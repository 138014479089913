import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {getFacilitatorStatsSheets} from 'helpers/stats-helper';
import {
	checkIfModuleGroupIsCompleted,
	getLastCompletedModuleId,
	getModuleGroupCompletedDate
} from 'helpers/module-helper';
import {getText} from 'helpers/language-helper';
import dayjs from 'dayjs';
import './facilitator-download-stats.scss';

const FacilitatorDownloadStats = ({languageId, game, players}) => {
	
	/* Get scenario data */
	const scenarioId = (game && game.scenarioId ? game.scenarioId : 'scenario-1');
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});

	/* Prepare sheets */
	let statsSheets = getFacilitatorStatsSheets(languageId, scenarioId);

	/* Get player stats */
	players.forEach((player) => {
		const created = (player.created ? dayjs(player.created).format('DD/MM-YY') : '-');
		const lastLogin = (player.lastLogin ? dayjs(player.lastLogin).format('DD/MM-YY') : '-');
		const playerStatsObject = {
			id: player.id,
			created: created,
			lastLogin: lastLogin,
		};
		const lastCompletedModuleId = getLastCompletedModuleId(player, scenarioId);
		if (lastCompletedModuleId) {
			const lastCompletedModule = getText(scenarioData.modulesData.find((m) => {
				return m.id === lastCompletedModuleId;
			}).title, languageId);
			playerStatsObject['lastCompletedModule'] = lastCompletedModule;
		}

		scenarioData.moduleGroups.forEach((moduleGroup) => {
			const isCompleted = checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, player);
			const completedDate = (isCompleted
				? getModuleGroupCompletedDate(moduleGroup.moduleIds, player)
				: null
			);
			const formattedCompletedDate = (completedDate
				? dayjs(completedDate).format('DD/MM-YY') 
				: null
			);
			
			playerStatsObject['group-' + moduleGroup.id] = (isCompleted
				? formattedCompletedDate ? formattedCompletedDate : 'ok'
				: '-'
			);
		});

		const sheetIndex = statsSheets.findIndex((sheet) => {return sheet.id === 'players';});
		if (sheetIndex >= 0) statsSheets[sheetIndex].data.push(playerStatsObject);
	});

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

	return (
		<div className="FacilitatorDownloadStats">
			<ExcelFile 
				element={
					<button className="FacilitatorDownloadStats-btn">
						{getText(facilitatorUiTexts.downloadData, languageId)}
					</button>
				}
			>
				{statsSheets.map((sheet, index) => {
					return (
						<ExcelSheet key={index} data={sheet.data} name={sheet.name}>
							{sheet.columns.map((column, cIndex) => {
								return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
							})}
						</ExcelSheet>
					);
				})}
			</ExcelFile>
		</div>
	);
};

FacilitatorDownloadStats.propTypes = {
	languageId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
};

export default FacilitatorDownloadStats;