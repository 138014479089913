import React, { useEffect, useRef, useState } from 'react';
import appConfig from 'config/app.config';
import { getRandomNumber } from 'helpers/math-helper';
import {ReactComponent as Bird} from 'assets/images/bird.svg';
import './bird-generator.scss';

const BirdGenerator = () => {
	// OBS: THIS COMPONENT NEEDS AN APPCONFIG VARIABLES NAMED 'screenBaseWidth'
	const birdInterval = useRef(null);
	const [birds, setBirds] = useState([]);
	const [birdBaseId, setBirdBaseId] = useState(1);
	const maxFlockSize = 10;
	const minFlockSize = 3;
	const maxBirdDelay = 5;
	const maxBirdSpread = 15; // em
	const birdMaxAnimationLength = 6;
	const birdMinAnimationLength = 4;
	const birdFlightInterval = 6; // Seconds
	const minBirdSize = 1; // em
	const maxBirdSize = 2; // em

	useEffect(() => {
		birdInterval.current = setInterval(() => {
			const currentScreenWidth = window.screen.width;
			const currentTime = new Date();
			const newBirds = [];
			const newFlockSize = getRandomNumber(minFlockSize, maxFlockSize);
			for (let i = 0; i < newFlockSize; i++) {
				let newAnimationLength = getRandomNumber(birdMinAnimationLength, birdMaxAnimationLength);
				newAnimationLength = currentScreenWidth / appConfig.screenBaseWidth * newAnimationLength;
				const newBirdDelay = getRandomNumber(0, maxBirdDelay);
				const newBirdSpread = getRandomNumber(0, maxBirdSpread);
				const newBirdSize = getRandomNumber(minBirdSize, maxBirdSize);

				const newBird = {
					animationLength: newAnimationLength,
					birdTopDisplacement: newBirdSpread,
					birdDelay: newBirdDelay,
					birdSize: newBirdSize,
				};

				newBirds.push(newBird);
			}

			setBirdBaseId(currentTime.getSeconds());
			setBirds(newBirds);
		}, (window.screen.width / appConfig.screenBaseWidth * birdMaxAnimationLength * 1000) +
			birdFlightInterval * 1000);

		return () => {
			if (birdInterval && birdInterval.current) {
				clearInterval(birdInterval.current);
			}
		};
	}, []);

	return (
		<div className='BirdGenerator'>
			<div className='BirdGenerator-birds'>
				{birds.map((bird, index) => {
					return (
						<Bird key={birdBaseId + '' + index}
							id={birdBaseId + '' + index}
							className='BirdGenerator-bird'
							style={{
								top: bird.birdTopDisplacement + 'em',
								animationDelay: bird.birdDelay + 's',
								animationDuration: bird.animationLength + 's',
								width: bird.birdSize + 'em',
								height: bird.birdSize + 'em',
								opacity: bird.birdSize / maxBirdSize,
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default BirdGenerator;