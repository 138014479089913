import React from 'react';
import PropTypes from 'prop-types';
import {imagesData} from 'data/images-data';
import './image-loader.scss';

const ImageLoader = ({type}) => {
	const images = imagesData[type] ? imagesData[type] : [];
	return (
		<div className={'ImageLoader ' + type}>
			{images.map((file, index) => {
				let imgSrc = 0;
				try {
					imgSrc = require('../../../assets/images/' + file);
				} catch (error) {
					console.error('Could not preload image: ' + file);
				}
				if (!imgSrc) return null;
				return <img key={index} src={imgSrc} alt="preload" />;
			})}
		</div>
	);
};

ImageLoader.propTypes = {
	type: PropTypes.string.isRequired,
};

export default ImageLoader;