import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getAllSurveysOfScenarios} from 'helpers/survey-helper';
import {getText} from 'helpers/language-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import Select from 'components/ui/select/select';
import SurveyResults from 'components/ui/survey-results/survey-results';
import './facilitator-surveys-popup.scss';


const FacilitatorSurveysPopup = ({languageId, gameData, gameSurveysResponses, togglePopup}) => {
	/* Get all surveys in game, fix ids */
	const scenarioId = gameData.scenarioId ? gameData.scenarioId : 'scenario-1';
	const surveysData = getAllSurveysOfScenarios([scenarioId]);
	surveysData.forEach((surveyData) => {
		surveyData.id = surveyData.taskId;
	});

	/* Selected survey id */
	const [surveyId, setSurveyId] = useState(surveysData.length > 0 ? surveysData[0].id : null);

	return (
		<div className="FacilitatorSurveysPopup" onClick={() => {togglePopup(null);}}>
			<div className="FacilitatorSurveysPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="FacilitatorSurveysPopup-header">
					<div className="FacilitatorSurveysPopup-title">
						<span>{getText(facilitatorUiTexts.surveys, languageId)}</span>
					</div>
					<div
						className="FacilitatorSurveysPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>
				</div>

				{/* Popup body */}
				<div className="FacilitatorSurveysPopup-body">
					{/* Select survey */}
					<div className="FacilitatorSurveysPopup-selectSurvey">
						{surveysData.length > 0 && <Select 
							isDisabled={false}
							type="scenario"
							languageId={languageId}
							defaultId={(surveysData.length > 0 ? surveysData[0].id : null)}
							selectedId={surveyId}
							options={surveysData}
							onSelect={setSurveyId}
						/>}
					</div>

					{/* Survey results */}
					{surveysData.length > 0 && <div className="FacilitatorSurveysPopup-result">
						<SurveyResults 
							languageId={languageId}
							surveyId={surveyId}
							surveysData={surveysData}
							surveysResponses={gameSurveysResponses}
						/>
					</div>}
				</div>
			</div>
		</div>
	);
};

FacilitatorSurveysPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameData: PropTypes.object.isRequired,
	gameSurveysResponses: PropTypes.array.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default FacilitatorSurveysPopup;
