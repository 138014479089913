const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-correct-images/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-correct-images/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-correct-images/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-correct-images/option-4.svg',

	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-clothes-dont-touch/option-6.svg',
	
	'modules/tasks/multiple-choice/s1-m2-contamination-allergy/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-contamination-allergy/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-contamination-allergy/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-contamination-allergy/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-contamination-allergy/option-5.svg',

	'modules/tasks/multiple-choice/s1-m2-guests/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-guests/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-guests/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-guests/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-guests/option-5.svg',

	'modules/tasks/spot-errors/s1-m2-meat/background.svg',
	'modules/tasks/spot-errors/s1-m2-production-errors/background.svg',
];

export {
	moduleImages
};