import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {shuffleArray} from 'helpers/array-helper';
import {sortPoints} from 'data/points-data';
import SortDndContainer from './sort-dnd-container';
import SortDndItem from './sort-dnd-item';
import SortDndPreview from './sort-dnd-preview';
import TaskIntro from '../task-intro/task-intro';
import Audio from 'components/ui/audio/audio';
import './sort.scss';

const Sort = (props) => {
	const {
		languageId, 
		playerTaskData, 
		taskData, 
		moduleData,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask
	} = props;

	/* Get items to be sorted */
	const getItems = () => {
		/* Get items from player data or data file */
		let items = (playerTaskData && playerTaskData.sortedItems
			? playerTaskData.sortedItems // TODO: check it matches?
			: taskData.items.map((item) => {return {itemId: item.id, containerId: null};})
		);

		/* Shuffle items and return */
		return shuffleArray(items);
	};

	/* Track sorted items & errors */
	const [sortedItems, setSortedItems] = useState(getItems());
	const [errors, setErrors] = useState(0);


	/* Update sorted items if new task */
	useEffect(() => {
		const items = getItems();
		setSortedItems(items);
		setErrors(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Find item
	 * @param {number} itemId 
	 * @returns 
	 */
	const handleFindItem = (itemId) => {
		const item = sortedItems.find((item) => {return item.itemId === itemId;});
		return (item ? item.containerId : null);
	};

	/**
	 * Move item to new container
	 * @param {number} itemId 
	 * @param {string} containerId 
	 */
	const handleMoveItem = (itemId, containerId) => {		
		/* Update logged time */
		updateLoggedTime();
		
		/* Move item */
		let newSortedItems = JSON.parse(JSON.stringify(sortedItems));
		const itemIndex = newSortedItems.findIndex((item) => {return item.itemId === itemId;});
		newSortedItems[itemIndex].containerId = containerId;
		setSortedItems(newSortedItems);

		/* Check if it is an error */
		let newErrors = errors;
		const itemData = taskData.items.find((itemData) => {return itemData.id === itemId;});
		const isCorrect = (itemData.categoryIds.indexOf(containerId) >= 0);
		if (!isCorrect) {
			newErrors += 1;
			setErrors(newErrors);
		}

		/* Update streak */
		const instantEffects = [{type: 'streak', isCorrectAnswer: isCorrect}];

		/* Additional effects */
		if (isCorrect && itemData.effects) {
			itemData.effects.forEach((effect) => {
				instantEffects.push(effect);
			});
		}

		/* Check if task is completed: all items are placed into a category && it is the right category */
		if (
			newSortedItems.every((item) => {return item.containerId !== null;}) && 
			newSortedItems.every((item) => {
				const itemData = taskData.items.find((itemData) => {return itemData.id === item.itemId;});
				return (itemData.categoryIds.indexOf(item.containerId) >= 0);
			})
		) {
			completeTask(newSortedItems, newErrors, instantEffects);
		} else {
			handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Complete task
	 */
	const completeTask = (newSortedItems, newErrors, instantEffects) => {
		/* Return if not all items have been sorted */
		if (newSortedItems.some((item) => {return item.containerId === null;})) return;

		/* Calculate points */
		let points = sortPoints.minPoints;
		let pointIndex = sortPoints.pointLimits.findIndex((limit) => {return newErrors <= limit;});
		if (pointIndex >= 0) points = sortPoints.pointValues[pointIndex];


		/* Save completed task */
		handleCompleteTask(
			'sort', 
			points, 
			newErrors, 
			instantEffects,
			{sortedItems: newSortedItems}
		);
	};

	return (
		<div className={'Sort ' + taskData.layout + (taskData.subtype ? ' ' + taskData.subtype : '')}>
			<div id="sortContainer" className="Sort-content">
				<div id="taskIntro" className="Sort-intro">
					<TaskIntro 
						languageId={languageId}
						moduleId={moduleData.id}
						text={taskData.text ? getText(taskData.text, languageId) : null}
						image={taskData.image}
						fileName={languageId + '-' + taskData.taskId}
					/>
				</div>
				<div className="Sort-itemsAndCategories">
					{/* Category containers */}
					<div className="Sort-categories">
						{taskData.categories && taskData.categories.map((categoryData) => {
							return (
								<div key={categoryData.id} className="Sort-category">
									{categoryData.title && <div className="Sort-categoryTitle">
										<span>{getText(categoryData.title, languageId)}</span>
									</div>}
									<div className="Sort-categoryBody">
										<SortDndContainer
											layout={taskData.layout}
											containerId={categoryData.id}
											handleFindItem={handleFindItem}
											handleMoveItem={handleMoveItem}
										>
											{sortedItems.filter((item) => {
												return item.containerId === categoryData.id;
											}).map((item) => {
												const itemData = taskData.items.find((itemData) => {
													return itemData.id === item.itemId;
												});
												if (!itemData) return null;

												let isDraggable = true;
												let classes = null;
												if (
													(playerTaskData && playerTaskData.isCompleted)
												) {
													isDraggable  = false;
													classes = ['completed'];
												} else {
													const isPlacedCorrect = 
												(itemData.categoryIds.indexOf(item.containerId) >= 0);
													classes = (isPlacedCorrect ? ['animateCorrect'] : ['animateWrong']);
													if (isPlacedCorrect) {
														isDraggable = false;
													}
												}


												return (
													<SortDndItem 
														key={itemData.id} 
														isDraggable={isDraggable} 
														isPlaced={true}
														layout={taskData.layout}
														classes={classes}
														itemId={itemData.id}
													>
														{taskData.subtype !== 'images' && <span>
															{itemData.text ? getText(itemData.text, languageId) : null}
														</span>}
														<div className="Sort-audio">
															<Audio 
																type='task-option'
																fileName={languageId + '-' 
																	+ taskData.taskId + '-' + itemData.id}
															/>
														</div>
													</SortDndItem>
												);
											})}
										</SortDndContainer>
									</div>
								</div>
							);
						})}
					</div>

					{/* Items to be sorted */}
					<div className="Sort-items">
						{sortedItems.map((item) => {
							if (item.containerId !== null) {
								if (taskData.subtype === 'images') {
									return (
										<div 
											key={item.itemId}
											className={'Sort-itemShadow item-' + item.itemId +
												(taskData.layout ? ' ' + taskData.layout : '')}
										/>
									);
								}
								return null;
							}
							const itemData = taskData.items.find((itemData) => {
								return itemData.id === item.itemId;
							});
							if (!itemData) return null;
							const isDraggable = (!playerTaskData || !playerTaskData.isCompleted);
							return (
								<SortDndItem 
									key={item.itemId} 
									isDraggable={isDraggable} 
									isPlaced={false}
									layout={taskData.layout}
									itemId={item.itemId}
								>
									{taskData.subtype !== 'images' && <span>
										{itemData.text ? getText(itemData.text, languageId) : null}
									</span>}
									<div className="Sort-audio">
										<Audio 
											type='task-option'
											color={'blue'}
											fileName={languageId + '-' 
												+ taskData.taskId + '-' + itemData.id}
										/>
									</div>
								</SortDndItem>
							);
						})}
					</div>
					<SortDndPreview 
						layout={taskData.layout}
						subtype={taskData.subtype}
						languageId={languageId} 
						itemsData={taskData.items} 
					/>
				</div>
			</div>
		</div>
	);
};

Sort.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default Sort;
