/* Effect */
const effectTemplate = {
	mainTemplate: {
		type: null, // feedback, points
		conditions: null, // if no conditions, effect is always applied
		value: 0,
		feedback: null
	},
	feedbackTemplate: {
		characterId: 'manager pose-1 coat-1 hairnet-1', // manager
		text: null
	},
};


export {effectTemplate};
