import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './sort-dnd-item.scss';

const SortDndItem = ({isDraggable, isPlaced, itemId, layout, classes, children}) => {
	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {type: 'item', id: itemId},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (itemId === monitor.getItem().id);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Opacity (invisible if dragging) */
	const opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'SortDndItem item-' + itemId + (layout ? ' ' + layout : '') 
		+ (!isDraggable ? ' disabled' : '') + (isPlaced ? ' placed' : '');
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}
	
	return (
		<div
			ref={drag}
			className={className}
			style={{opacity}}
		>{children}</div>
	);
};

SortDndItem.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	isPlaced: PropTypes.bool.isRequired,
	itemId: PropTypes.number.isRequired,
	layout: PropTypes.string,
	classes: PropTypes.array,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.any
	]),
};

export default SortDndItem;
