import Instructions from 'components/game/module/engines/instructions/instructions';
import Image from 'components/game/module/engines/image/image';
import Order from 'components/game/module/engines/order/order';
import MultipleChoice from 'components/game/module/engines/multiple-choice/multiple-choice';
import SpotErrors from 'components/game/module/engines/spot-errors/spot-errors';
import Sort from 'components/game/module/engines/sort/sort';
import Survey from 'components/game/module/engines/survey/survey';
import Match from 'components/game/module/engines/match/match';

const moduleComponents = {
	instructions: Instructions,
	image: Image,
	'multiple-choice': MultipleChoice,
	order: Order,
	sort: Sort,
	'spot-errors': SpotErrors,
	survey: Survey,
	match: Match
};

export {
	moduleComponents
};