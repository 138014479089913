import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import appConfig from 'config/app.config';
import {getGameUrl} from 'helpers/game-helper';
import {playerDataTemplate} from 'data/templates/player-data-template';
import {languagesData} from 'data/languages-data';
import Loading from 'components/loading/loading';
import PlayerLobby from 'components/player-lobby/player-lobby';
import ChooseLanguage from 'components/choose-language/choose-language';
import Game from 'components/game/game';

class PlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isConnectedToGame: false,
			page: 'game',
			userData: null,
			gameData: null,
			gamesData: [],
		};
		this.unsubscribeUser = null;
		this.unsubscribeGameData = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Login is required - get user data from database */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Log last login date */
				const lastLogin = dayjs(new Date()).format('YYYY-MM-DD');
				this.updateUser({lastLogin: lastLogin});

				/* Get game url */
				const gameUrl = getGameUrl(window.location.pathname);

				if (!gameUrl || gameUrl.length === 0 || gameUrl === '/') {
					/* Site root */
					if (this.state.userData.gameId) {
						/* Connected to game, get redirect uri  */
						const db = firebase.firestore();
						db.collection(appConfig.gamesDbName).doc(this.state.userData.gameId).get().then((doc) => {
							if (doc.exists && doc.data() && doc.data().url) {
								/* Redirect url found, redirect to game url */
								window.location.href = '/' + doc.data().url;
							} else {
								/* Redirect url not found, show default page (lobby) */ 
								this.setState({isLoading: false});						
							}
						}).catch(() => {
							/* Show default page (lobby) */ 
							this.setState({isLoading: false});
						});
					} else {
						/* Not connected to gamee, show default page (lobby) */ 
						this.setState({isLoading: false});
					}
				} else {
					/* Get games */
					const db = firebase.firestore();
					db.collection(appConfig.gamesDbName).get().then((querySnapshot) => {
						let gamesData = [];
						querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
						this.setState({gamesData}, () => {		
							/* Find game with matching url */
							const urlGameData = gamesData.find((game) => {return game.url === gameUrl;});
							if (urlGameData) {
								/* Game exists, subscribe to game data */
								this.subscribeToGameData(urlGameData.id).then(() => {
									/* Only access to 1 game  */
									if (!this.state.userData.gameId) {
										/* Player not connected to any game, connect to this game */
										this.updateUser({gameId: this.state.gameData.id}).then(() => {
											this.setState({isLoading: false, isConnectedToGame: true});	
										}).catch(() => {
											// TODO: handle error updating player
											this.setState({isLoading: false});
										});
									} else {
										/* Player is connected to a game */
										if (this.state.userData.gameId === urlGameData.id) {
											/* Player is connected to this game */
											this.setState({isLoading: false, isConnectedToGame: true});	
										} else {
											/* Player is connected to a different game */
											this.setState({isLoading: false});
										}
									}
								}).catch(() => {
									// TODO: handle error subscribing to game
									this.setState({isLoading: false});
								});
							} else {
								/* No game corresponds to the url / root url */	
								this.setState({isLoading: false});
							}
						});
					});
				}
			} else {
				// Error subscribing to user
				console.error('Could not subscribe to user: ', response.error);
				this.setState({isLoading: false});
			}
		}).catch((error) => {
			// TODO: display error
			console.error('Could not subscribe to user: ', error);
			this.setState({isLoading: false});
		});
		
	};
	
	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Cancel subscribtions */
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();
	};

	/**
	 * Subscribe to user (player) data
	 */
	subscribeToUser = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.playersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {
						resolve({ status: 'success' });
					});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			}
			);
		});
	};

	/**
	 * Subscribe to game data
	 * @param {string} gameId 
	 * @returns {Promise}
	 */
	subscribeToGameData = (gameId) => {
		/* Cancel previous subscription */
		if (this.unsubscribeGameData !== null) this.unsubscribeGameData();

		/* Subscribe to game data */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGameData = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Save game data to state */
					const gameData = {id: doc.id, ...doc.data()};
					this.setState({ gameData: gameData }, () => {resolve({status: 'ok'});});
				} else {
					/* Error: No game data */
					resolve({status: 'error', error: 'no-game-data'});
				}
			},
			(error) => {
				/* Error: Could not get game data */
				console.error('Could not get game data: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Update user (player) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Get user id */
		const userId = this.state.userData.id;

		/* Update user data */
		const db = firebase.firestore();
		const userRef = db.collection(appConfig.playersDbName).doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Add survey response to database
	 * @param {object} surveyResponse 
	 * @returns 
	 */
	saveSurveyResponse = (surveyResponse) => {
		const db = firebase.firestore();
		return db.collection(appConfig.surveysDbName).add(surveyResponse);
	};

	/**
	 * Switch game
	 * @param {string} gameId 
	 */
	switchPlayerGame = (gameId) => {
		const db = firebase.firestore();
		return db.collection(appConfig.playersDbName).doc(this.state.userData.id).update({
			gameId: gameId,
			modules: []
		});
	};

	/**
	 * Reset player game data for this game
	 */
	resetPlayerGameData = () => {
		/* Reset data in database */
		this.updateUser(
			{...Object.assign({}, 
				playerDataTemplate, 
				{gameId: this.state.userData.gameId}
			)}
		);
	};

	/**
	 * Choose language
	 * @param {string} languageId 
	 */
	handleChooseLanguage = (languageId) => {
		this.updateUser({languageId}).then(() => {
			if (this.state.page !== 'game') this.setState({page: 'game'});
		});
	};

	/**
	 * Update player info
	 * @param {string} name 
	 * @param {string} email 
	 */
	updatePlayerInfo = (name, email) => {
		this.updateUser({name, email}).then(() => {
			if (this.state.page !== 'game') this.setState({page: 'game'});
		});
	};


	/**
	 * Go to page
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading languageId={this.props.languageId} handleLogout={this.props.handleLogout} />
			);
		}

		/* Get language id (use browser language if user has not selected language) */
		const languageId = (
			this.state.userData && 
			this.state.userData.languageId && 
			languagesData.some((l) => {return l.id === this.state.userData.languageId;})
				? this.state.userData.languageId
				: this.props.languageId
		);

		/* Not connected to game */
		if (!this.state.isConnectedToGame) {
			return (
				<PlayerLobby 
					languageId={languageId}
					userData={this.state.userData}
					gameData={this.state.gameData}
					gamesData={this.state.gamesData}
					switchPlayerGame={this.switchPlayerGame}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		const scenarioId = (this.state.gameData.scenarioId ? this.state.gameData.scenarioId : 'scenario-1');

		/* Select language */
		if (!this.state.userData.languageId || this.state.page === 'choose-language') {
			return (
				<ChooseLanguage 
					languageId={languageId}
					role={'player'}
					scenarioId={scenarioId}
					handleChooseLanguage={this.handleChooseLanguage}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		return (
			<DndProvider options={HTML5toTouch}>
				<Game 
					languageId={languageId}
					backgroundStatus={this.props.backgroundStatus}
					userData={this.state.userData}
					gameData={this.state.gameData}
					scrollToTop={this.props.scrollToTop}
					handleGoToPage={this.handleGoToPage}
					updateUser={this.updateUser}
					resetPlayerGameData={this.resetPlayerGameData}
					saveSurveyResponse={this.saveSurveyResponse}
					handleLogout={this.props.handleLogout}
					setBackground={this.props.setBackground}
					handleShakeScreen={this.props.handleShakeScreen}

				/>
			</DndProvider>
		);
	};
};

PlayerController.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	userId: PropTypes.string,
	scrollToTop: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired

};

export default PlayerController;
