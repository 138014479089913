import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './star.scss';

const Star = ({page, isFilled}) => {

	const [showFilledStar, setShowFilledStar] = useState(isFilled);
	const [animateStar, setAnimateStar] = useState(page !== 'facilitator');

	useEffect(() => {
		if (!isFilled && showFilledStar) setShowFilledStar(false);

		if (!isFilled || showFilledStar) return;

		if (page === 'facilitator') {
			setShowFilledStar(true);
			return;
		}
		
		const timeout = setTimeout(() => {
			setShowFilledStar(true);
			setAnimateStar(true);
		}, 400);
		
		return () => {
			/* Component will unmount */
			if (timeout) clearTimeout(timeout);
		};
	}, [isFilled, showFilledStar, page]);
	
	return (
		<div className={'Star'}>
			{animateStar && 
				<div className='Star-glow' />
			}
			<div className={'Star-image ' 
				+ page 
				+ (showFilledStar ? ' filled' : '') 
				+ (animateStar ? ' animate' : '')}
			/>
		</div>
	);
};

Star.propTypes = {
	page: PropTypes.string.isRequired,
	isFilled: PropTypes.bool
};

export default Star;
