import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import {generalUiTexts} from 'data/ui-texts/general-ui-texts';
import Button from 'components/ui/button/button';
import './loading.scss';

const Loading = ({languageId, handleLogout}) => {
	return (
		<div className="Loading">
			<div className="Loading-panel" >
				{handleLogout && <div className="Loading-logoutBtn" > 
					<Button
						text={getText(loginUiTexts.logout, languageId)}
						classes={['logout']}
						onClick={() => {handleLogout();}}
					/>
				</div>}
				<div className="Loading-content">
					<div className="Loading-circles">
						<div className="Loading-circle" />
						<div className="Loading-circle" />
						<div className="Loading-circle" />
					</div>
					<div className="Loading-text">{getText(generalUiTexts.loading, languageId)}...</div>
				</div>
			</div>
		</div>
	);
};

Loading.propTypes = {
	languageId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func
};

export default Loading;
