import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as ManagerSVG} from 'assets/images/characters/manager.svg';
import './manager.scss';

const Manager = ({page, managerId = 'a', managerPose}) => {
	/* Default poses */
	let bodyPose = null;
	let coatPose = null;
	let hairnetPose = null;

	/* Custom poses */
	const poses = managerPose.split(' ');
	poses.forEach((pose) => {
		if (pose.includes('pose-')) bodyPose = pose;
		if (pose.includes('coat-')) coatPose = pose;
		if (pose.includes('hairnet-')) hairnetPose = pose;
	});

	/* Blinking */
	const blinkTimeout = useRef(null);
	const timeout = useRef(null);

	const minBlinkTime = 1000;
	const maxBlinkTime = 4000;

	const [eyesAreClosed, setEyesAreClosed] = useState(false);

	const blink = () => {
		const randomInterval = Math.round(Math.random() * (maxBlinkTime - minBlinkTime)) + minBlinkTime;
		if (blinkTimeout.current) clearTimeout(blinkTimeout.current);
		blinkTimeout.current = setTimeout(() => {
			/* Close eyes */
			setEyesAreClosed(true);

			if (timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				/* Open eyes */
				setEyesAreClosed(false);
				clearTimeout(timeout.current);
				
				/* Repeat loop with new random */
				blink();
			}, 100);
		}, randomInterval);
	};

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		blink();
		
		return () => {
			/* Component will unmount */
			if (blinkTimeout.current) clearTimeout(blinkTimeout.current);
			if (timeout.current) clearTimeout(timeout.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'Manager ' + page + ' manager-' + managerId 
			+ ' ' 
			+ (bodyPose ? ' ' + bodyPose : '') 
			+ (coatPose ? ' ' + coatPose : '') 
			+ (hairnetPose ? ' ' + hairnetPose : '')
			+ (eyesAreClosed ? ' blinking' : '')
		}>
			<ManagerSVG />
		</div>
	);
};

Manager.propTypes = {
	page: PropTypes.string.isRequired,
	managerId: PropTypes.string,
	managerPose: PropTypes.string,
};

export default Manager;
