import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import Button from 'components/ui/button/button';
import './admin-delete-game-popup.scss';

const AdminDeleteGamePopup = (props) => {
	const {languageId, gameId, gamesData, toggleShowDeleteGamePopup, deleteGame, setSelectedGameId} = props;

	/* Step (warning-1 or warning-2) */
	const [warningNumber, setWarningNumber] = useState(1);

	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Get game data */
	const gameData = gamesData.find((game) => {return (game.id === gameId);});

	/* Popup title & text */
	const title = getText(adminUiTexts.deleteGamePopup.title, languageId);
	const text = JSON.parse(JSON.stringify(
		getText(adminUiTexts.deleteGamePopup['text' + warningNumber], languageId)
	)).replace('%gameName%', (gameData ? '"' + gameData.name + '"' : '-'));


	/** 
	 * Handle confirm
	 */
	const handleConfirm = () => {
		if (warningNumber === 1) {
			setWarningNumber(2);
		} else {
			handleDeleteGame();
		}
	};

	/**
	 * Handle delete game
	 */
	const handleDeleteGame = () => {
		if (isLoading) return; 

		setErrorMsg(null);
		setSuccessMsg(null);
		setIsLoading(true);
		
		deleteGame(gameId).then((response) => {
			if (response.status === 'success') {
				setSelectedGameId();
				toggleShowDeleteGamePopup();
			} else {
				console.error(response);
			}
		});
	};

	if (!gameData) return null;

	return (
		<div className="AdminDeleteGamePopup" onClick={() => {toggleShowDeleteGamePopup();}}>
			<div className="AdminDeleteGamePopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminDeleteGamePopup-header">
					<div className="AdminDeleteGamePopup-title">{title}</div>
					<div 
						className="AdminDeleteGamePopup-closeBtn" 
						onClick={() => {toggleShowDeleteGamePopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminDeleteGamePopup-body">
					<div className="AdminDeleteGamePopup-text">{renderMarkdown(text)}</div>
					<div className={'AdminDeleteGamePopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
					<div className="AdminDeleteGamePopup-buttons">
						<div className="AdminDeleteGamePopup-confirmBtn">
							<Button 
								isLoading={isLoading}
								classes={['facilitator']}
								text={getText(adminUiTexts.deleteGamePopup.confirmBtn, languageId)}
								onClick={() => {handleConfirm();}}
							/>
						</div>
						<div className="AdminDeleteGamePopup-cancelBtn">
							<Button 
								classes={['facilitator']}
								text={getText(adminUiTexts.deleteGamePopup.cancelBtn, languageId)}
								onClick={() => {toggleShowDeleteGamePopup();}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminDeleteGamePopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	toggleShowDeleteGamePopup: PropTypes.func.isRequired,
	deleteGame: PropTypes.func.isRequired,
	setSelectedGameId: PropTypes.func.isRequired,
};

export default AdminDeleteGamePopup;
