import {useEffect} from 'react';
import firebaseInit from 'firebase-init';
import {getBrowserLanguage} from 'helpers/language-helper';
import LoginController from 'components/login/login-controller';
import ImageLoader from './ui/image-loader/image-loader';
import './app.scss';

function App() {
	/* Language id */
	const browserLanguageId = getBrowserLanguage();
	
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();
	});


	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};


	return (
		<div id="App" className="App">
			<LoginController languageId={browserLanguageId} scrollToTop={scrollToTop} />
			<ImageLoader type="basic" />
		</div>
	);
}

export default App;
