import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {loginUiTexts} from 'data/ui-texts/login-ui-texts';
import Button from 'components/ui/button/button';
import './create-user.scss';

const CreateUser = (props) => {
	const {
		isLoading,
		userCreated,
		languageId,
		email,
		password,
		passwordRepeat,
		feedback,
		handleInput,
		handleCreateUser,
		setFacilitatorComponentType
	} = props;

	if (userCreated) {
		return (
			<div className="CreateUser">
				<div className="CreateUser-title">
					<span>{getText(loginUiTexts.createNewUser, languageId)}</span>
				</div>
				<div className="CreateUser-body">
					<div className="CreateUser-info">
						<span>{getText(loginUiTexts.createUserFeedback, languageId)}</span>
					</div>
					<div 
						className="CreateUser-resetPasswordBtn"
						onClick={()=>{setFacilitatorComponentType('login');}}
					><span>{getText(loginUiTexts.goToLoginBtn, languageId)}</span></div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="CreateUser">
			<div className="CreateUser-title">
				<span>{getText(loginUiTexts.createNewUser, languageId)}</span>
			</div>
			<div className="CreateUser-body">
				<form className="CreateUser-form" onSubmit={handleCreateUser}>
					<input
						name="email"
						type="text"
						placeholder={getText(loginUiTexts.email, languageId)}
						autoComplete="section-createUser email"
						value={email ? email : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="password"
						type="password"
						placeholder={getText(loginUiTexts.password, languageId)}
						autoComplete="section-createUser password"
						value={password ? password : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="passwordRepeat"
						type="password"
						placeholder={getText(loginUiTexts.passwordRepeat, languageId)}
						autoComplete="section-createUser repeatpassword"
						value={passwordRepeat ? passwordRepeat : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button
						isLoading={isLoading}
						classes={['login']}
						text={getText(loginUiTexts.createUserBtn, languageId)}
						onClick={(event) => {handleCreateUser(event);}}
					/>
					<p className="CreateUser-errorMessage">
						<span>{feedback}</span>
					</p>
				</form>
				<div 
					className="CreateUser-resetPasswordBtn"
					onClick={()=>{setFacilitatorComponentType('login');}}
				><span>{getText(loginUiTexts.goToLoginBtn, languageId)}</span></div>
			</div>
		</div>
	);	
};

CreateUser.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	userCreated: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired,
	setFacilitatorComponentType: PropTypes.func.isRequired
};

export default CreateUser;