import appConfig from 'config/app.config';

/**
 * 
 * @param {*} url 
 * @param {*} gamesData 
 * @returns 
 */
export function checkIfValidUrl(url, gamesData) {
	let isValid = true;
	let feedback = null;

	if (!url || url.length === 0) {
		/* No input */
		isValid = false;
		feedback = 'emptyField';
	} else {
		/* Check if using a reserved value */
		if (url.toLowerCase() === appConfig.faclitatorSectionUri) {
			isValid = false;
			feedback = 'reservedUrl';
		} else {
			/* Check if valid format (only lowercase letters, numbers and '-') */
			const matches = url.toLowerCase().match(/^([a-z0-9-]+)$/);
			if (!matches) {
				isValid = false;
				feedback = 'invalidUrl';
			} else {
				const isUniqueUrl = !(gamesData.some((game) => {return game.url === url;}));
				if (!isUniqueUrl) {
					isValid = false;
					feedback = 'notUniqueUrl';
				}
			}
		}
		
	}

	return {isValidUrl: isValid, urlFeedback: feedback};
}

/**
 * Check if an email is valid
 * @param {string} email 
 * @returns 
 */
export function checkIfValidEmail(email) {
	let isValid = true;
	let feedback = null;

	if (!email || email.length === 0) {
		/* No input */
		isValid = false;
		feedback = 'emptyField';
	} else {
		/* Check if valid e-mail format */
		const matches = email.toLowerCase().match(
			// eslint-disable-next-line max-len
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		if (!matches) {
			isValid = false;
			feedback = 'invalidEmail';
		}
	}

	return {isValidEmail: isValid, emailFeedback: feedback};
};


export function checkIfValidName(name) {
	let isValid = true;
	let feedback = null;
	let chars = null;

	// Identifies all unsafe characters, such as '<', '>' and '\'
	const validationRegex = /[^0-9a-zæøåA-ZÆØÅ\-_\s]/g;

	const trimmedInput = name.trim();
	if (!trimmedInput || trimmedInput.length === 0) {
		isValid = false;
		feedback = 'emptyField';
	} else {
		const matches = [...new Set([...trimmedInput.matchAll(validationRegex)].flat())];
		if (matches.length > 0) {
			isValid = false;
			feedback = 'invalidName';
			chars = matches;
		}
	}

	return {isValidName: isValid, nameFeedback: feedback, invalidChars: chars};
};


/**
 * Generate unique id
 * @param {string} seed
 * @param {array} exisitingIds
 * @returns 
 */
export function generateUniqueId(seed, existingIds) {
	let base = seed.toLocaleLowerCase().trim().replace(/ /g, '-');
	let suffix = 0;

	let promise = new Promise((resolve, reject) => {
		function checkId() {
			const tempId = base + (suffix > 0 ? '-' + suffix : '');

			if (existingIds.some((id) => {return id === tempId;})) {
				suffix += 1;
			} else {
				resolve({ id: tempId });
			}
		}
		checkId();
	});
	return promise;
};
