import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {facilitatorUiTexts} from 'data/ui-texts/facilitator-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import FacilitatorHeader from './facilitator-header';
import Button from 'components/ui/button/button';
import './facilitator-select-game.scss';

const FacilitatorSelectGame = ({languageId, userData, games, handleSelectGame, goToPage, handleLogout}) => {
	return (
		<div className="FacilitatorSelectGame">
			<div className="FacilitatorSelectGame-header">
				<FacilitatorHeader 
					languageId={languageId}
					userData={userData}
					gameData={null}
					goToPage={goToPage}
					handleLogout={handleLogout}
				/>
			</div>
			<div className="FacilitatorSelectGame-content">
				<div className="FacilitatorSelectGame-title">
					<span>{getText(facilitatorUiTexts.chooseGame, languageId)}</span>
				</div>
				{games.length === 0 && <div className="FacilitatorSelectGame-text">
					<span>{getText(facilitatorUiTexts.noGames, languageId)}</span>
				</div>}
				<div className="FacilitatorSelectGame-games">
					{games.map((game) => {
						const createdOn = (game && game.created 
							? dayjs(game.created).format('DD/MM-YY') : '-');
						const gameUrl = appConfig.gameUrl + '/' + (game && game.url ? game.url : '');	
						const scenarioId = (game && game.scenarioId ? game.scenarioId : 'scenario-1');
						const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
						return (
							<div key={game.id} className="FacilitatorSelectGame-game">
								<div className="FacilitatorSelectGame-gameHeader">
									<span>{game.name}</span>
								</div>
								<div className="FacilitatorSelectGame-gameBody">
									<div className="FacilitatorSelectGame-gameInfo">
										<span>{getText(facilitatorUiTexts.created, languageId)}: </span>
										<span>{createdOn}</span>
									</div>
									<div className="FacilitatorSelectGame-gameInfo">
										<span>{getText(facilitatorUiTexts.scenario, languageId)}: </span>
										<span>{getText(scenarioData.title, languageId)}</span>
									</div>
									<div className="FacilitatorSelectGame-gameInfo">
										<span>{getText(facilitatorUiTexts.url, languageId)}: </span>
										<span>{gameUrl}</span>
									</div>
									<div className="FacilitatorSelectGame-gameSelectBtn">
										<Button 
											classes={['facilitator']}
											text={getText(facilitatorUiTexts.choose, languageId)}
											onClick={() => {handleSelectGame(game.id);}}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>				
			</div>
		</div>
	);
};

FacilitatorSelectGame.propTypes = {
	languageId: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	games: PropTypes.array.isRequired,
	handleSelectGame: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorSelectGame;
