import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { getText } from 'helpers/language-helper';
import { modulesData } from 'data/scenarios/scenario-1/modules-data';
import { adminUiTexts } from 'data/ui-texts/admin-ui-texts';
import Button from 'components/ui/button/button';
import './admin-export-module-popup.scss';

const AdminExportModulePopup = ({languageId, togglePopup}) => {
	const [fileName, setFileName] = useState(null);
	const [jsonExportData, setJsonExportData] = useState(null);

	// Exports list of tasks to json file
	const exportTaskFile = (tasks) => {
		const correctedDataList = [];
		tasks.forEach((task) => {
			let newObject = {...task};

			if (newObject.hasOwnProperty('id')) {
				delete newObject.id;
			}

			// organizeTemplate -> not used && sortTemplate -> not used
			if (newObject.type !== 'organize' && newObject.type !== 'sort') {
				// backgroundModifier -> background + backgroundModifier
				if (newObject.hasOwnProperty('backgroundModifier')) {
					let background = newObject.background ? newObject.background : '';
					
					// backgroundModifier - zoom-in-no-bench -> zoom-in no-bench
					let backgroundModifier = newObject.backgroundModifier ? newObject.backgroundModifier : '';
					if (backgroundModifier === 'zoom-in-no-bench') {
						backgroundModifier = 'zoom-in no-bench';
					}

					newObject.background = background + ' ' + backgroundModifier;

					delete newObject.backgroundModifier;
				}

				// multiple choice type -> mc
				if (newObject.type === 'multiple-choice') {
					newObject.type = 'mc';
				}

				// maxPoints -> deprecated
				if (newObject.hasOwnProperty('maxPoints')) {
					delete newObject.maxPoints;
				}

				// image -> textImages[]
				if (newObject.hasOwnProperty('image')) {
					newObject.textImages = [newObject.image];

					delete newObject.image;
				}

				if (newObject.hasOwnProperty('options')) {
					newObject.options.forEach((option, index) => {
						// item id -> just increment ignore set value
						option.id = index + 1;

						// effect - feedback - text -> effect - text {}
						if (option.effects.length > 0) {
							option.effects.forEach((effect) => {

								if (effect.feedback) {
									effect.text = effect.feedback.text;
									effect.characterId = effect.feedback.characterId;
									
									delete effect.feedback;
								}
							});
						}
					});
				}

				if (newObject.hasOwnProperty('items')) {
					newObject.items.forEach((item, index) => {
						// item id -> just increment ignore set value
						item.id = index + 1;
					});
				}

				// doneEffects -> onCompleteEffects
				if (newObject.hasOwnProperty('doneEffects')) {
					newObject.doneEffects.forEach((effect) => {
						if (effect.feedback) {
							effect.text = effect.feedback.text;
							effect.characterId = effect.feedback.characterId;
							
							delete effect.feedback;
						}
					});

					newObject.onCompleteEffects = newObject.doneEffects;

					delete newObject.doneEffects;
				}

				// spotErrors - type -> spot-items
				if (newObject.type === 'spot-errors') {
					newObject.type = 'spot-items';

					// spotErrors - errors -> items
					// spotErrors - item -> add isCorrect = true
					newObject.items = newObject.errors.map((error) => {
						return {...error, isCorrect: true};
					});

					delete newObject.errors;
				}

				// spotErrors - doneWhenAllErrorsSpotted => doneWhenAllCorrectItemsSelected
				if (newObject.hasOwnProperty('doneWhenAllErrorsSpotted')) {
					newObject.doneWhenAllCorrectItemsSelected = newObject.doneWhenAllErrorsSpotted;

					delete newObject.doneWhenAllErrorsSpotted;
				}

				// survey shuffleOptions default -> changed to false
				if (newObject.type === 'survey') {
					delete newObject.shuffleOptions;
				}

				correctedDataList.push(newObject);
			}
		});

		const jsonResult = JSON.stringify(correctedDataList);
		setJsonExportData(jsonResult);
	};

	/**
	 * Handle download JSON file on json export data
	 */
	const handleDownloadJSON = () => {
		const file = new Blob([jsonExportData], { type: 'text/plain;charset=utf-8' });
		saveAs(file, fileName + '.txt');

		setJsonExportData(null);
	};

	return (
		<div className="AdminExportModulePopup" onClick={() => {togglePopup();}}>
			<div className="AdminExportModulePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className='AdminExportModulePopup-header'>
					<span>{getText(adminUiTexts.modules, languageId)}</span>
				</div>
				<div className='AdminExportModulePopup-moduleList'>
					{modulesData.map((module, index) => {
						return (
							<div key={index} className='AdminExportModulePopup-module'>
								<div className='AdminExportModulePopup-moduleName'>
									<span>{module.id}</span>
								</div>
								<div className='AdminExportModulePopup-exportButton'>
									<Button 
										classes={['facilitator']}
										text={getText(adminUiTexts.export, languageId)}
										onClick={() => {setFileName(module.id); exportTaskFile(module.tasks);}}
									/>
								</div>
							</div>
						);
					})}
				</div>

				<div className='AdminExportModulePopup-downloadButton'>
					<Button
						isDisabled={jsonExportData === null}
						classes={['facilitator']}
						text={fileName ? 
							getText(adminUiTexts.download, languageId) + ' ' + fileName
							:
							getText(adminUiTexts.downloadFile, languageId)
						}
						onClick={() => {handleDownloadJSON();}}
					/>
				</div>
			</div>
		</div>
	);
};

AdminExportModulePopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default AdminExportModulePopup;