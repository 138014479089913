import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import './select.scss';

const Select = ({isDisabled, languageId, type, defaultId, selectedId, options, extraParam, onSelect}) => {
	let [dropdownIsOpen, setDropDown] = useState(false);

	const toggleDropdown = () => {
		if (isDisabled) return;
		setDropDown(!dropdownIsOpen);
	};

	const handleSelect = (optionId, extraParam) =>  {
		if (isDisabled) return;
		onSelect(optionId, extraParam);
		toggleDropdown();
	};

	let selectedOption = options.find((option) => {return option.id === selectedId;});
	let defaultOption = options.find((option) => {return option.id === defaultId;});

	const selectedOptionText = (selectedOption
		? selectedOption.title ? getText(selectedOption.title, languageId) : selectedOption.id
		: defaultOption.title ? getText(defaultOption.title, languageId) : defaultOption.id
	);
	return (
		<div className={'Select ' + type + (dropdownIsOpen ? ' open' : '') + (isDisabled ? ' disabled' : '')}>
			<div className={'Select-panel Select-panel--' + selectedId} onClick={()=>{toggleDropdown();}}>
				<div className="Select-selected">
					<span>{selectedOptionText}</span>
				</div>
				<div className="Select-button" />
			</div>
			<div className="Select-dropdown">
				{options.map((option, index) => {
					const optionText = (option.title ? getText(option.title, languageId) : option.id);
					let isDisabled = (option.hasOwnProperty('isDisabled') && option.isDisabled === true);
					let className = 'Select-option';
					if (selectedId === option.id) className += ' Select-option--selected';
					if (isDisabled) className += ' Select-option--disabled';
					return (
						<div 
							key={index} 
							className={className} 
							onClick={()=>{if (!isDisabled) handleSelect(option.id, extraParam);}}
						><span>{optionText}</span></div>
					);
				})}
			</div>
		</div>
	);
};


Select.propTypes = {
	isDisabled: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	defaultId: PropTypes.string.isRequired,
	selectedId: PropTypes.string,
	options: PropTypes.array.isRequired,
	extraParam: PropTypes.any,
	onSelect: PropTypes.func.isRequired,
};

export default Select;