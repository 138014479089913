// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const facilitatorUiTexts = {
	admin: {
		da: 'Admin',
		en: 'Admin',
	},
	loggedInAs: {
		da: 'Logget ind som',
		en: 'Logged in as',
	},
	noGames: {
		da: 'Du er ikke registreret som facilitator af nogen spil.',
		en: 'You are not registered as a facilitator of any games.',
	},

	chooseGame: {
		da: 'Vælg spil',
		en: 'Choose game',
	},
	gameName: {
		da: 'Spilnavn',
		en: 'Game name',
	},
	gameUrl: {
		da: 'Spil URL',
		en: 'Game URL',
	},
	url: {
		da: 'URL',
		en: 'URL',
	},
	created: {
		da: 'Oprettet',
		en: 'Created',
	},
	scenario: {
		da: 'Scenarie',
		en: 'Scenario',
	},
	choose: {
		da: 'Vælg',
		en: 'Choose',
	},
	backToChooseGames: {
		da: 'Vælg andet spil',
		en: 'Choose another game',
	},

	showInactivePlayers: {
		da: 'Vis inaktive spillere',
		en: 'Show inactive players',
	},
	employeeNumber: {
		da: 'Medarbejder-nr.',
		en: 'Employee no.',
	},
	email: {
		da: 'E-mail',
		en: 'E-mail',
	},
	name: {
		da: 'Navn',
		en: 'Name'
	},
	lastLogin: {
		da: 'Sidste login',
		en: 'Last login',
	},
	status: {
		da: 'Status',
		en: 'Status',
	},
	active: {
		da: 'Aktiv',
		en: 'Active',
	},
	inactive: {
		da: 'Inaktiv',
		en: 'Inactive',
	},
	deactivateBtn: {
		da: 'Deaktiver',
		en: 'Deactivate',
	},
	reactivateBtn: {
		da: 'Reaktiver',
		en: 'Reactivate',
	},
	lastCompletedModule: {
		da: 'Senest afsluttede modul',
		en: 'Last completed module',
	},
	moduleGroup: {
		da: 'Modulgruppe',
		en: 'Module group',
	},
	module: {
		da: 'Modul',
		en: 'Module',
	},
	completion: {
		da: 'Progress',
		en: 'Progress',
	},
	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
	},
	minutes: {
		da: 'minutter',
		en: 'minutes',
	},
	avgMistakes: {
		da: 'Antal fejl (gns)',
		en: 'Number of errors (avg)',
	},
	task: {
		da: 'Opgave',
		en: 'Task',
	},
	type: {
		da: 'Type',
		en: 'Type',
	},
	mistakes: {
		da: 'Fejl (gns)',
		en: 'Mistakes (avg)',
	},
	attempts: {
		da: 'Forsøg',
		en: 'Attempts',
	},
	players: {
		da: 'Spillere',
		en: 'Players',
	},
	viewSurveys: {
		da: 'Se surveys',
		en: 'View surveys',
	},
	surveys: {
		da: 'Surveys',
		en: 'Surveys',
	},
	question: {
		da: 'Spørgsmål',
		en: 'Question'
	},
	noEnoughSurveyResponses: {
		da: 'Der er ikke nok svar til at vise resultatet.',
		en: 'There are not enough responses to show the result.'
	},
	downloadData: {
		da: 'Download data',
		en: 'Download data',
	},

	resetPlayerModule: {
		title: {
			da: `Nulstil modulgruppe`,
			en: 'Reset module group',
		},
		text: {
			da: `Vil du nulstille brugerens data for %moduleGroup%? 
				<br /><br />Dette kan ikke gøres om.`,
			en: `Do you want to reset the user's data for %moduleGroup%?
				<br /><br/>This cannot be reversed.`,
		},
		confirmBtn: {
			da: 'Fortsæt',
			en: 'Continue',
		},
		cancelBtn: {
			da: 'Annuller',
			en: 'Cancel',
		}
	},

	resetPlayerPopup: {
		text: {
			da: `Vil du nulstille brugeren?
				<br /><br />Det vil sige at alle moduler skal tages forfra.`,
			en: `Do you want to reset this user's progress?
				<br /><br />This will reguire for them to replay all modules.`,
		},
		noBtn: {
			da: 'Nej, aktiver',
			en: 'No, keep active',
		},
		yesBtn: {
			da: 'Ja, nulstil og aktiver',
			en: 'Yes, reset and activate',
		},
		cancelBtn: {
			da: 'Annuller',
			en: 'Cancel',
		}
	},
};


module.exports = {
	facilitatorUiTexts
};