import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import {ReactSVG} from 'react-svg';
import {getAudioSrc} from 'helpers/audio-helper';
import './audio.scss';

const Audio = ({type, color, fileName}) => {
	/* Ref to audio player */
	const audioPlayerRef = useRef();

	/* Audio src */
	const [audioSrc, setAudioSrc] = useState(null);

	/**
	 * Update audio src
	 */
	const updateAudioSrc = () => {
		setAudioSrc(null);
		getAudioSrc(fileName).then((result) => {
			if (result.status === 'success') {
				setAudioSrc(result.src);
			}
		});
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Save ref to current audio player */
		const newAudioPlayerRef = (audioPlayerRef ? audioPlayerRef.current : null);

		/* Component will ummount */
		return () => {
			if (newAudioPlayerRef && newAudioPlayerRef.audio && newAudioPlayerRef.audio.current) {
				/* Stop playback */
				newAudioPlayerRef.audio.current.pause();
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Audio src changed
	 */
	useEffect(() => {
		/* Save ref to current audio player */
		const newAudioPlayerRef = (audioPlayerRef ? audioPlayerRef.current : null);

		/* Component will ummount */
		return () => {
			if (newAudioPlayerRef && newAudioPlayerRef.audio && newAudioPlayerRef.audio.current) {
				/* Stop playback */
				newAudioPlayerRef.audio.current.pause();
			}
		};
	}, [audioSrc]);

	/**
	 * Update audio ref and src if props change
	 */
	useEffect(() => {
		/* Stop audio  */
		if (audioPlayerRef && audioPlayerRef.current) {
			audioPlayerRef.current.audio.current.pause();
		}
		/* Update audio src */
		if (fileName) {
			updateAudioSrc();
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileName]);

	/* No audio src, return null */
	if (!audioSrc) return null;

	/* Play / pause btn */
	let playBtnComponent = <ReactSVG src={require('../../../assets/images/icons/icon-audio-play.svg').default} />;
	let pauseBtnComponent = <ReactSVG src={require('../../../assets/images/icons/icon-audio-pause.svg').default} />;
	if (color === 'blue') {
		playBtnComponent = <ReactSVG src={require('../../../assets/images/icons/icon-audio-play-blue.svg').default} />;
		pauseBtnComponent = 
			<ReactSVG src={require('../../../assets/images/icons/icon-audio-pause-blue.svg').default} />;
	}
	

	if (!fileName) {
		return null;
	}
	
	/* Return audio player */
	return (
		<div className={'Audio' + (type ? ' ' + type : '') + (color ? ' ' + color : ' blue')} >
			<AudioPlayer
				ref={audioPlayerRef}
				muted={false}
				preload="metadata"
				volume={1}
				autoPlay={false}
				src={audioSrc}
				width="100%"
				height="100%"
				showSkipControls={false}
				showJumpControls={false}
				showDownloadProgress={false}
				showFilledProgress={false}
				showFilledVolume={false}
				customIcons={{play: playBtnComponent, pause: pauseBtnComponent}}
			/>
		</div>
	);
};

Audio.propTypes = {
	type: PropTypes.string.isRequired,
	color: PropTypes.string,
	fileName: PropTypes.string
};



export default Audio;