import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
// import {renderMarkdown} from 'helpers/language-helper';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import Button from 'components/ui/button/button';
import './admin-game-popup.scss';

const AdminGamePopup = (props) => {
	const {
		languageId,
		gameId, 
		gamesData, 
		usersData, 
		handleSelectGame, 
		updateGameData, 
		toggleShowAddFacilitatorPopup,
		toggleRemoveFacilitatorPopup,
		toggleShowDeleteGamePopup
	} = props;

	/* Get game data */
	const gameData = gamesData.find((game) => {return game.id === gameId;});

	/* Game info */
	const [gameName, setGameName] = useState(gameData && gameData.name ? gameData.name : '');
	useEffect(() => {
		setGameName(gameData && gameData.name ? gameData.name : '');
	}, [gameData]);

	/* Input game info */
	const inputGameInfo = (event) => {
		if (event.target.name === 'name') setGameName(event.target.value);
	};

	/* Save new store info */
	const saveGameInfo = (event) => {
		const updates = {[event.target.name]: event.target.value};
		updateGameInfo(updates);
		event.target.blur();
	};

	/* Created on / by */
	const createdOn = (gameData && gameData.created ? dayjs(gameData.created).format('DD/MM-YY') : '-');
	const createdBy = (gameData && gameData.createdByUserId 
		?	usersData.some((user) => {return user.id === gameData.createdByUserId;})
			? usersData.find((user) => {return user.id === gameData.createdByUserId;}).email : '-'
		: '-'
	);

	/* Game url */
	const gameUrl = appConfig.gameUrl + '/' + (gameData && gameData.url ? gameData.url : '');	

	/* Get facilitators info */
	let gameFacilitators = [];
	if (gameData && gameData.facilitatorEmails && gameData.facilitatorEmails.length > 0) {
		gameData.facilitatorEmails.forEach((facilitatorEmail) => {
			const facilitatorData = usersData.find((user) => {
				return (user.email === facilitatorEmail);
			});
			if (facilitatorData) {
				gameFacilitators.push(facilitatorData);
			} else {
				gameFacilitators.push({email: facilitatorEmail, name: null});
			}
		});
	}

	


	/**
	 * Update game info
	 * @param {object} updates 
	 */
	const updateGameInfo = (updates) => {
		updateGameData(gameId, updates);
	};

	if (!gameData) return null;

	return (
		<div className="AdminGamePopup" onClick={() => {handleSelectGame();}}>
			<div className="AdminGamePopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminGamePopup-header">
					<div className="AdminGamePopup-storeName">
						<span>{getText(adminUiTexts.gameName, languageId)}:</span>
						<input 
							type="text" 
							name="name"
							placeholder={getText(adminUiTexts.name, languageId)}
							value={gameName ? gameName : ''}
							onBlur={() => {setGameName(gameData.name ? gameData.name : '');}}
							onChange={(e) => {inputGameInfo(e);}}
							onKeyUp={(e) => {if (e.key === 'Enter') saveGameInfo(e);}}
						/>
					</div>
					<div className="AdminGamePopup-closeBtn" onClick={() => {handleSelectGame();}} />
				</div>

				{/* Popup body */}
				<div className="AdminGamePopup-body">
					{/* Game info */}
					<div className="AdminGamePopup-gameInfo">
						<div className="AdminGamePopup-gameInfoRow createdOn">
							<div className="AdminGamePopup-gameInfoLabel">
								<span>{getText(adminUiTexts.created, languageId)}: </span>
							</div>
							<div className="AdminGamePopup-gameInfoValue"><span>{createdOn}</span></div>
						</div>
						<div className="AdminGamePopup-gameInfoRow createdBy">
							<div className="AdminGamePopup-gameInfoLabel">
								<span>{getText(adminUiTexts.createdBy, languageId)}: </span>
							</div>
							<div className="AdminGamePopup-gameInfoValue"><span>{createdBy}</span></div>
						</div>
						<div className="AdminGamePopup-gameInfoRow url">
							<div className="AdminGamePopup-gameInfoLabel">
								<span>{getText(adminUiTexts.gameUrl, languageId)}: </span>
							</div>
							<div className="AdminGamePopup-gameInfoValue">
								<span>{gameUrl}</span>
							</div>
						</div>
					</div>


					{/* Facilitators */}
					<div className="AdminGamePopup-addFacilitatorBtn">
						<Button 
							classes={['facilitator']} 
							text={getText(adminUiTexts.gamePopup.addFacilitator, languageId)} 
							onClick={() => {toggleShowAddFacilitatorPopup(true);}}
						/>
					</div>
					<div className="AdminGamePopup-facilitators">
						<div className="AdminGamePopup-facilitatorsHeader">
							<div className="AdminGamePopup-facilitatorsCell email">
								<span>{getText(adminUiTexts.gamePopup.email, languageId)}</span>
							</div>
							<div className="AdminGamePopup-facilitatorsCell created">
								<span>{getText(adminUiTexts.created, languageId)}</span>
							</div>
							<div className="AdminGamePopup-facilitatorsCell lastLogin">
								<span>{getText(adminUiTexts.gamePopup.lastLogin, languageId)}</span>
							</div>
							<div className="AdminGamePopup-facilitatorsCell remove" />
						</div>
						<div className="AdminGamePopup-facilitatorsBody">
							{gameFacilitators.map((facilitator, index) => {
								const created = (facilitator.created 
									? dayjs(facilitator.created).format('DD/MM-YY') : '-');
								const lastLogin = (facilitator.lastLogin 
									? dayjs(facilitator.lastLogin).format('DD/MM-YY') : '-');
								return (
									<div key={index} className="AdminGamePopup-facilitatorsRow">
										<div className="AdminGamePopup-facilitatorsCell email">
											<span>{facilitator.email}</span>
										</div>
										<div className="AdminGamePopup-facilitatorsCell created">
											<span>{created}</span>
										</div>
										<div className="AdminGamePopup-facilitatorsCell lastLogin">
											<span>{lastLogin}</span>
										</div>
										<div className="AdminGamePopup-facilitatorsCell remove">
											<div className="AdminGamePopup-removeFacilitatorBtn">
												<Button 
													classes={['facilitator']}
													text={getText(adminUiTexts.gamePopup.removeFacilitator, languageId)}
													onClick={() => {toggleRemoveFacilitatorPopup(facilitator.email);}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					{/* Delete game button */}
					<div className="AdminGamePopup-deleteGameBtn">
						<Button
							classes={['facilitator']}
							text={getText(adminUiTexts.gamePopup.deleteGame, languageId)}
							onClick={() => {toggleShowDeleteGamePopup(true);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);

};

AdminGamePopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string.isRequired,
	gamesData: PropTypes.array.isRequired,
	usersData: PropTypes.array.isRequired,
	handleSelectGame: PropTypes.func.isRequired,
	updateGameData: PropTypes.func.isRequired,
	toggleShowAddFacilitatorPopup: PropTypes.func.isRequired,
	toggleRemoveFacilitatorPopup: PropTypes.func.isRequired,
	toggleShowDeleteGamePopup: PropTypes.func.isRequired,
};

export default AdminGamePopup;
