import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import dayjs from 'dayjs';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {getDownloadSheet} from 'helpers/player-progress-helper';
import {
	checkIfModuleGroupIsCompleted,
	getModuleGroupCompletedDate,
} from 'helpers/module-helper';
import {getText} from 'helpers/language-helper';
import './admin-download-player-progress.scss';

const AdminDownloadPlayerProgress = ({languageId, games, players}) => {

	/* Prepare sheet */
	let downloadSheet = getDownloadSheet(languageId);


	/* Loop over players */
	players.forEach((player) => {
		const gameData = games.find((g) => {return g.id === player.gameId;});
		if (gameData) {
			const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario-1');
			const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
			scenarioData.moduleGroups.forEach((moduleGroup) => {
				const isCompleted = checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, player);
				
				// Note, this is the completed date in our system
				const startDate = (isCompleted ? getModuleGroupCompletedDate(moduleGroup.moduleIds, player) : null);

				// Note, this is 1 year after completion date
				const endDate = (startDate ? dayjs(startDate).add(1, 'year') : null);
				
				const sheetObj = {
					employeeId: player.id,
					courseName: getText(moduleGroup.title, languageId),
					courseId: moduleGroup.courseNumber,
					startDate: (startDate ? dayjs(startDate).format('DD.MM.YYYY') : '-'),
					endDate: (endDate ? endDate.format('DD.MM.YYYY') : '-')
				};
				downloadSheet.data.push(sheetObj);
				
			});
		}
	});

	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
	const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
	return (
		<div className="AdminDownloadPlayerProgress">
			<ExcelFile 
				element={
					<button className="AdminDownloadPlayerProgress-btn">
						{getText(adminUiTexts.downloadPlayerProgress, languageId)}
					</button>
				}
			>
				<ExcelSheet data={downloadSheet.data} name={downloadSheet.name}>
					{downloadSheet.columns.map((column, cIndex) => {
						return <ExcelColumn key={cIndex} label={column.label} value={column.value} />;
					})}
				</ExcelSheet>
			</ExcelFile>
		</div>
	);
};

AdminDownloadPlayerProgress.propTypes = {
	languageId: PropTypes.string.isRequired,
	games: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
};

export default AdminDownloadPlayerProgress;