import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {shuffleArray} from 'helpers/array-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {multipleChoicePoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import TaskIntro from 'components/game/module/engines/task-intro/task-intro';
import Audio from 'components/ui/audio/audio';
import './multiple-choice.scss';

const MultipleChoice = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData, 
		moduleData,
		updateLoggedTime,
		handleInstantTaskEffects, 
		handleCompleteTask
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Task id */
	const [taskId, setTaskId] = useState(null);

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	
	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);
	const selectOptionsText = (taskData.showRequiredNumberOfAnswers
		? getText(playerUiTexts.choose, languageId) + ' ' + numberOfCorrectAnswers + ':'
		: null
	);

	
	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];
		if (playerTaskData && playerTaskData.selectedOptionIds) optionIds = playerTaskData.selectedOptionIds;
		return optionIds;
	};

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	const [points, setPoints] = useState(0);
	
	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setSelectedOptionIds(getSelectedOptionIds());
		setPoints(0);
		setAnimateSelectedOptions(false);
		setTaskId(taskData.id);
		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);


	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;

		/* Update logged time */
		updateLoggedTime();

		/* Enable animation */
		setAnimateSelectedOptions(true);

		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);


		/* Get option data */
		const selectedOptionData = (taskData.options 
			? taskData.options.find((o) => {return o.id === optionId;})
			: 0
		);
		
		/* Handle effects */
		let newPoints = points;
		let instantEffects = [];
		if (selectedOptionData && selectedOptionData.effects.length > 0) {
			selectedOptionData.effects.forEach((effect, effectIndex) => {
				if (effect.type === 'points') {
					/* Effect: points */
					newPoints += effect.value;	
				} else {
					/* Effect: feedback, popup, special points, new avatar item */
					let effectObj = JSON.parse(JSON.stringify(effect));
					if (effect.type === 'feedback' && effect.feedback) {
						effectObj.feedback.moduleId = moduleData.id;
						effectObj.feedback.audioFileName = languageId + '-' + taskData.taskId 
							+ '-' + selectedOptionData.id + '-' + effect.type + '-' + (effectIndex + 1);
					}
					instantEffects.push(effectObj);
				}
			});
		}

		/* Error/success streak */
		if (!taskData.isDilemma && selectedOptionData) {
			instantEffects.push({type: 'streak', isCorrectAnswer: selectedOptionData.isCorrect});
		}

		/* Update points */
		setPoints(newPoints);

		/* Check if task is completed */
		const taskIsCompleted = (taskData.isDilemma
			? taskData.numberOfAnswersToSelect === newSelectedOptionIds.length
			: checkIfAllCorrectOptionsAreSelected(newSelectedOptionIds)
		);
		if (taskIsCompleted) {
			/* Complete task, instant effects -> normal effects */
			completeTask(newSelectedOptionIds, newPoints, instantEffects);
		} else {
			/* Instant effects (shown while task is still not completed) */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Check if all correct options are selected (i.e. if task is complete)
	 * @param {array} newSelectedOptionIds 
	 * @returns 
	 */
	const checkIfAllCorrectOptionsAreSelected = (newSelectedOptionIds) => {
		let allCorrectOptionsAreSelected = true;
		taskData.options.forEach((optionData) => {
			if (optionData.isCorrect === true && newSelectedOptionIds.indexOf(optionData.id) < 0) {
				allCorrectOptionsAreSelected = false;
			}
		});
		return allCorrectOptionsAreSelected;
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds 
	 * @param {number} newPoints
	 */
	const completeTask = (newSelectedOptionIds, newPoints, effects) => {
		/* Prepare array of effects */
		let newEffects = (effects ? [...effects] : []);

		/* Get number of wrong answers */
		let wrongAnswers = 0;
		if (!taskData.isDilemma) {
			newSelectedOptionIds.forEach((selectedOptionId) => {
				if (!taskData.options.some((optionData) => {
					return (optionData.id === selectedOptionId && optionData.isCorrect === true);
				})) {
					wrongAnswers += 1;
				}
			});
		}

		/* Calculate points */
		let updatedPoints = 0;
		if (!taskData.isDilemma) {
			const maxPoints = Math.min(taskData.options.length, multipleChoicePoints.basePoints);
			updatedPoints = Math.max(
				multipleChoicePoints.minimumPoints, 
				maxPoints - (wrongAnswers * multipleChoicePoints.minusPointsPerWrongAnswers)
			);
		}
		updatedPoints += newPoints;
		
		/* Get effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, wrongAnswers);

				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						updatedPoints += effect.value;
					} else {
						/* Effect: feedback, popup */
						newEffects.push(effect);
					}
				}
			});
		}

		/* Save completed task */
		handleCompleteTask(
			'multiple-choice', 
			updatedPoints,
			wrongAnswers, 
			newEffects,
			{selectedOptionIds: newSelectedOptionIds}
		);
	};

	return (
		<div className={'MultipleChoice ' 
			+ languageId
			+ (taskData.isDilemma ? ' dilemma' : '')
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			{taskData.id === taskId && <div className="MultipleChoice-content">
				<div id="taskIntro" className="MultipleChoice-intro">
					<TaskIntro 
						languageId={languageId}
						moduleId={moduleData.id}
						selectOptionsText={selectOptionsText}
						text={taskData.text ? getText(taskData.text, languageId) : null}
						image={taskData.image}
						fileName={languageId + '-' + taskData.taskId}
					/>
				</div>
				<div id="multipleChoiceOptions" className="MultipleChoice-options">
					
					<div className={'MultipleChoice-optionsWrap'}>
						{optionIds.map((optionId, index) => {
							const optionData = taskData.options.find((option) => {return option.id === optionId;});
							if (!optionData) return null;
							const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
							let optionClass = 'MultipleChoice-option';
							if (isSelected) optionClass += ' selected';
							if (!isSelected && isCompleted) optionClass += ' completed';
							if (isSelected && !taskData.isDilemma && optionData.hasOwnProperty('isCorrect')) {
								if (optionData.isCorrect) {
									optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
								} else {
									optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
								}
							}						
							if (taskData.layout) optionClass += ' option-' + optionData.id + ' position-' + (index + 1);

							return (
								<div 
									key={optionData.id} 
									className={optionClass} 
									onClick={() => {selectOptionId(optionData.id);}}
								>
									<span></span> {/* Used for image */}
									<span>{optionData.text ? getText(optionData.text, languageId) : null}</span>
									{(optionData.text && taskData.subtype !== 'images') && 
										<div className="MultipleChoice-audio">
											<Audio 
												type='task-option'
												languageId={languageId}
												moduleId={moduleData.id}
												fileName={languageId + '-' + taskData.taskId + '-' + optionData.id}
											/>
										</div>
									}
								</div>
							);
						})}
					</div>
				</div>
			</div>}
		</div>
	);
};

MultipleChoice.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default MultipleChoice;
