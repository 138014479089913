import appConfig from 'config/app.config';

/**
 * Get number of miliseconds since last activity timestamp
 * @param {number} timestamp 
 * @returns 
 */
export function getTimeSinceLastActivity(timestamp) {
	/* Get difference in miliseconds */
	const seconds = Date.now() - timestamp;

	/* Return differnce (max value is inactivity limit) */
	return Math.min(seconds, appConfig.inactivityLimitSeconds * 1000);
};

/**
 * Calculate total time spent in session, return formatted string: mm:ss
 * @param {object} playerModuleSession 
 * @returns 
 */
export function getTimeSpentInSessionFormatted(playerModuleSession) {
	const timeSpent = (playerModuleSession && playerModuleSession.milisecondsPlayed 
		? Math.floor(playerModuleSession.milisecondsPlayed / 1000.) 
		: 0
	);
	const timeSpentMinutes = (timeSpent > 0 ? Math.floor(timeSpent / 60.) : 0);
	const timeSpentSeconds = timeSpent - (timeSpentMinutes * 60);

	const timeSpentFormatted = (timeSpentMinutes < 10 ? '0' : '') + timeSpentMinutes + ':' +
		(timeSpentSeconds < 10 ? '0' : '') + timeSpentSeconds;
	
	return timeSpentFormatted;
}


/**
 * Gets time spend in given module, in minutes
 * @param {Object} module 
 * @returns int
 */
export function getTimeSpentInModule(playerModuleData) {
	const sessionPlayTimes = playerModuleData.sessions.map((session) => {
		return (session.milisecondsPlayed ? session.milisecondsPlayed : 0);
	});
	const milisecondsPlayed = sessionPlayTimes.reduce(
		(previous, current) => {
			return previous + current;
		}
	);
	if (milisecondsPlayed && milisecondsPlayed > 0) return Math.round(milisecondsPlayed / (60. * 1000.));
	return 0;
}

/**
 * Calculates the average time spent among all players for the given module data
 * @param {object} modulePlayerData 
 * @returns calculated average of time spent as minutes, with up to one decimal point.
 */
export function getAverageTimeSpent(modulePlayerData) {
	let totalTimeSpent = 0;
	modulePlayerData.forEach((playerGameData) => {totalTimeSpent += getTimeSpentInModule(playerGameData);});
	const averageTimeSpent = totalTimeSpent / modulePlayerData.length;

	return averageTimeSpent > 0 ? averageTimeSpent.toFixed(1) : averageTimeSpent;
};