import React, {Component} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import Loading from 'components/loading/loading';
import Admin from 'components/admin/admin';
import apiHelper from 'helpers/api-helper';
import dayjs from 'dayjs';

class AdminController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			gamesData: [],
			usersData: [],
			playersData: [],
			sortProperty: 'name',
			sortDirection: 'ASC'
		};
		this.unsubscribeGamesData = null;
		this.unsubscribeUsersData = null;
		this.unsubscribePlayersData = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to games, users and tags data */
		Promise.all([
			this.subscribeToGamesData(), 
			this.subscribeToUsersData(), 
			this.subscribeToPlayersData()
		]).then((responses) => {
			if (responses[0].status === 'success' && responses[1].status === 'success') {
				this.setState({isLoading: false});
			} else {
				// TODO: handle loading error
				this.setState({isLoading: false});
			}
		});
	};
	
	/**
	 * Subscribe to all games
	 * @returns {Promise}
	 */
	subscribeToGamesData = () => {
		/* Cancel previous subscription */
		if (this.unsubscribeGamesData !== null) this.unsubscribeGamesData();
		
		/* Subscribe to games */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGamesData = db.collection(appConfig.gamesDbName).onSnapshot((querySnapshot) => {
				let gamesData = [];
				querySnapshot.forEach((doc) => {gamesData.push({id: doc.id, ...doc.data()});});
				this.setState({gamesData: gamesData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get games data */
				console.error('Could not get games data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all users
	 * @returns {Promise}
	 */
	subscribeToUsersData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribeUsersData !== null) this.unsubscribeUsersData();
			
		/* Subscribe to users */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeUsersData = db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let usersData = [];
				querySnapshot.forEach((doc) => {usersData.push({id: doc.id, ...doc.data()});});
				this.setState({usersData: usersData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get users data */
				console.error('Could not get users data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to all players
	 * @returns {Promise}
	 */
	subscribeToPlayersData = () => {
		/* Cancel previous subscribtion */
		if (this.unsubscribePlayersData !== null) this.unsubscribePlayersData();
			
		/* Subscribe to users */
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribePlayersData = db.collection(appConfig.playersDbName).onSnapshot((querySnapshot) => {
				let playersData = [];
				querySnapshot.forEach((doc) => {playersData.push({id: doc.id, ...doc.data()});});
				this.setState({playersData: playersData}, () => {resolve({status: 'success'});});
			},
			(error) => {
				/* Error: Could not get users data */
				console.error('Could not get players data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Create game
	 */
	createGame = (scenarioId, gameName, url, facilitatorEmail) => {
		const facilitatorEmails = (facilitatorEmail && facilitatorEmail.length > 0 ? [facilitatorEmail] : []);
		return new Promise((resolve) => {
			apiHelper('admin/create-game', {
				created: dayjs(new Date()).format('YYYY-MM-DD'),
				createdByUserId: this.props.userData.id,
				scenarioId: scenarioId,
				facilitatorEmails: facilitatorEmails,
				name: gameName,
				url: url,
			}).then(
				(response) => {
					// Success
					if (response.status === 'success' && response.gameId) {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Clean player ids. Calls api to clean up all player ids, that are not 8 numbers long
	 * Cannot clean up player ids that contain letters
	 */
	cleanPlayerIds = () => {
		return new Promise((resolve) => {
			apiHelper('admin/player-code-cleanup').then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Delete game
	 * @param {string} gameId 
	 */
	deleteGame = (gameId) => {
		return new Promise((resolve) => {
			apiHelper('admin/delete-game', {gameId}).then(
				(response) => {
					// Success
					if (response.status === 'success') {
						resolve({status: 'success'});
					} else {
						console.error(response);
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					console.error(rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};

	/**
	 * Add facilitator to game
	 * @param {*} gameId 
	 * @param {*} email 
	 */
	addFacilitatorToGame = (gameId, email) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).doc(gameId).get().then((doc) => {
				if (doc.exists) {
					let facilitatorEmails = doc.data().facilitatorEmails ? [...doc.data().facilitatorEmails] : [];
					const emailIndex = facilitatorEmails.indexOf(email);
					if (emailIndex < 0) {
						facilitatorEmails.push(email);
						db.collection(appConfig.gamesDbName).doc(gameId).update({facilitatorEmails}).then(() => {
							resolve({status: 'success'});	
						});
					} else {
						resolve({
							status: 'error', 
							feedback: getText(
								adminUiTexts.addFacilitatorToGamePopup.alreadyFacilitator, 
								this.props.languageId
							)
						});
					}
				} else {
					resolve({status: 'error', feedback: 'noGame'});
				}
			}, (error) => {
				/* Error: Could not get games data */
				resolve({status: 'error', feedback: 'noGame'});
			});
		});
	};

	/**
	 * Remove facilitator from game
	 * @param {*} gameId 
	 * @param {*} email 
	 */
	removeFacilitatorFromGame = (gameId, facilitatorEmail) => {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.gamesDbName).doc(gameId).get().then((doc) => {
				if (doc.exists) {
					let facilitatorEmails = doc.data().facilitatorEmails ? [...doc.data().facilitatorEmails] : [];
					const emailIndex = facilitatorEmails.indexOf(facilitatorEmail);
					if (emailIndex >= 0) {
						facilitatorEmails.splice(emailIndex, 1);
						db.collection(appConfig.gamesDbName).doc(gameId).update({facilitatorEmails}).then(() => {
							resolve({status: 'success'});	
						});
					} else {
						resolve({status: 'error', error: 'facilitator not connected to game'});	
					}
				} else {
					resolve({status: 'error', error: 'game does not exist'});
				}
			}, (error) => {
				/* Error: Could not get games data */
				console.error('Could not get games data: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};


	/**
	 * Update game data
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGameData = (gameId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.gamesDbName).doc(gameId).update(updates);
	};

	/**
	 * Update user data
	 * @param {string} userId 
	 * @param {object} updates 
	 * @returns 
	 */
	updateUserData = (userId, updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}
		
		/* Update game data */
		const db = firebase.firestore();
		return db.collection(appConfig.usersDbName).doc(userId).update(updates);
	};

	/**
	 * Sort players by property
	 * @param {string} property 
	 */
	sortGamesByProperty = (property) => {
		if (property === this.state.sortProperty) {
			const sortDirection = (this.state.sortDirection === 'ASC' ? 'DESC' : 'ASC');
			this.setState({sortDirection});
		} else {
			this.setState({
				sortProperty: property,
				sortDirection: 'ASC'
			});
		}
	};


	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading languageId={this.props.languageId} handleLogout={this.props.handleLogout} />
			);
		}

		/* Sort games */
		const sortedGames = sortArrayByProperty(
			this.state.gamesData, 
			this.state.sortProperty, 
			this.state.sortDirection
		);

		return (
			<Admin 
				languageId={this.props.languageId}
				sortProperty={this.state.sortProperty}
				sortDirection={this.state.sortDirection}
				userData={this.props.userData}
				gamesData={sortedGames}
				usersData={this.state.usersData}
				playersData={this.state.playersData}
				surveyResponses={this.props.surveyResponses}
				sortGamesByProperty={this.sortGamesByProperty}
				updateGameData={this.updateGameData}
				createGame={this.createGame}
				deleteGame={this.deleteGame}
				addFacilitatorToGame={this.addFacilitatorToGame}
				removeFacilitatorFromGame={this.removeFacilitatorFromGame}
				checkGameUrlUniqueness={this.checkGameUrlUniqueness}
				goToPage={this.props.goToPage}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

AdminController.propTypes = {
	userData: PropTypes.object.isRequired,
	languageId: PropTypes.string.isRequired,
	surveyResponses: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default AdminController;
