import PropTypes from 'prop-types';
import Manager from './manager';
import './character.scss';

const Character = ({page, characterId}) => {
	return (
		<div className={'Character ' + page + ' ' + characterId}>
			{characterId.includes('manager') &&
				<Manager page={page} managerPose={characterId} />}
		</div>
	);
};

Character.propTypes = {
	page: PropTypes.string.isRequired,
	characterId: PropTypes.string.isRequired,
};

export default Character;
