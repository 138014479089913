const scenariosData = [
	{
		id: 'scenario-1',
		title: {
			da: 'DC Beef fresh',
			en: 'DC Beef fresh',
		},
		languageIds: ['da', 'en'], // if adding 'en': remember to update lanugages-data.js
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
	{
		id: 'scenario-2',
		title: {
			da: 'DC Beef processed',
			en: 'DC Beef processed',
		},
		languageIds: ['da', 'en'], // if adding 'en': remember to update lanugages-data.js
		modulesData: require('data/scenarios/scenario-2/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-2/modules-data').moduleGroups,
	},
];

export {
	scenariosData
};