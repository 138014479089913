import React, {Component} from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import {getText} from 'helpers/language-helper';
import {errorUiTexts} from 'data/ui-texts/error-ui-texts';
import CreateUser from './create-user';

class CreateUserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			userCreated: false,
			email: null,
			password: null,
			passwordRepeat: null,
			feedback: null
		};
	};

	/**
	 * Update input fields
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};

	/**
	 * Handle create user request
 	 * @param {obj} event 
 	 */
	handleCreateUser = (event) => {
		event.preventDefault();

		if (this.state.isLoading) return;

		this.setState({isLoading: true}, () => {
			let email = this.state.email;
			let password = this.state.password;
			let passwordRepeat = this.state.passwordRepeat;
			let feedback = null;
	
			/* Check for empty fields */
			if (!email || !password || !passwordRepeat) {
				feedback = getText(errorUiTexts.emptyFields, this.props.languageId);
			}
	
			/* Check valid e-mail */
			if (!feedback && email.indexOf('@') < 0) {
				feedback = getText(errorUiTexts.invalidEmail, this.props.languageId);
			}
			
			/* Check if passwords match */
			if (!feedback) {
				if (password && password.length < 8) {
					feedback = getText(errorUiTexts.invalidPassword, this.props.languageId);
				} else if (password && password !== passwordRepeat) {
					feedback = getText(errorUiTexts.noPasswordMatch, this.props.languageId);
				}
			}
	
			if (feedback) {
				/* Error feedback */
				this.setState({isLoading: false, feedback});
			} else {
				/* Create user */
				this.createFirebaseUser(email, password);
			}
		});
	};

	/**
	 * Create user
	 * @param {string} email 
	 * @param {string} password 
	 */
	createFirebaseUser = (email, password) => {
		apiHelper('facilitator/create-facilitator', {
			email: email.toLowerCase(),
			password: password
		}).then(
			(response)=>{
				if (response.status === 'success') {
					this.setState({userCreated: true, isLoading: false});
				} else {
					console.error(response);
					let feedback = getText(errorUiTexts.unknownError, this.props.languageId);
					if (response.error === 'user-not-allowed') {
						feedback = getText(errorUiTexts.userNotAllowed, this.props.languageId);
					}
					this.setState({isLoading: false, feedback: feedback});
				}
			},
			(error) => {
				console.error(error);
				this.setState({isLoading: false, feedback: getText(errorUiTexts.unknownError, this.props.languageId)});
			}
		);
	};

	/**
	 * Render create user component
	 */
	render() {
		return (
			<CreateUser
				isLoading={this.state.isLoading}
				languageId={this.props.languageId}
				userCreated={this.state.userCreated}
				email={this.state.email}
				password={this.state.password}
				passwordRepeat={this.state.passwordRepeat}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleCreateUser={this.handleCreateUser}
				setFacilitatorComponentType={this.props.setFacilitatorComponentType}
			/>
		);		
	}
}

CreateUserController.propTypes = {
	languageId: PropTypes.string.isRequired,
	setFacilitatorComponentType: PropTypes.func.isRequired,
};

export default CreateUserController;