import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import { getText } from 'helpers/language-helper';
import SpeechBubble from 'components/ui/speech-bubble/speech-bubble';
import Audio from 'components/ui/audio/audio';
import Character from '../character/character';
import './feedback.scss';

const Feedback = ({languageId, feedbackData, onClick}) => {
	const feedbackText = (feedbackData.text 
		? getText(feedbackData.text, languageId)
		: ''
	);

	return (
		<div 
			className={'Feedback' + (feedbackData.type ? ' ' + feedbackData.type : '')}
			onClick={onClick}
		>
			<div className="Feedback-content">
				<SpeechBubble type="feedback" >
					<div className="Feedback-text">{renderMarkdown(feedbackText ? feedbackText : '')}</div>
					{feedbackData.audioFileName && <div className="Feedback-audio">
						<Audio 
							type='feedback'
							languageId={languageId}
							moduleId={feedbackData.moduleId}
							fileName={feedbackData.audioFileName}
						/>
					</div>}
				</SpeechBubble>
				
				<div className="Feedback-image">
					<Character page="feedback" characterId={feedbackData.characterId} />
				</div>
			</div>
		</div>
	);
};

Feedback.propTypes = {
	languageId: PropTypes.string.isRequired,
	feedbackData: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default Feedback;
