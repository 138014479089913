const moduleImages = [
	'modules/tasks/multiple-choice/s1-m1-hairnet/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-hairnet/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-hairnet/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-hairnet/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-hairnet/option-5.svg',
	
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-6.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-7.svg',
	'modules/tasks/multiple-choice/s1-m1-jewelry/option-8.svg',

	'modules/tasks/multiple-choice/s1-m1-not-allowed-dressing-cabinet/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-not-allowed-dressing-cabinet/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-not-allowed-dressing-cabinet/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-not-allowed-dressing-cabinet/option-4.svg',

	'modules/tasks/multiple-choice/s1-m1-nails/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-nails/option-6.svg',

	'modules/tasks/multiple-choice/s1-m1-hands/background.svg',

	'modules/tasks/spot-errors/s1-m1-open-closet/background.svg',
	'modules/tasks/spot-errors/s1-m1-person/background.svg',
];

export {
	moduleImages
};