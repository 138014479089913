import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import {getPlayerModuleData} from 'helpers/module-helper';
import './admin-module-stats.scss';

const AdminModuleStats = ({languageId, selectedModuleGroupData, players}) => {
	/* Get modules for all scenarios */
	const modulesData = [];
	scenariosData.forEach((scenarioData) => {
		scenarioData.modulesData.forEach((moduleData) => {
			modulesData.push(moduleData);
		});
	});

	const playersInModuleGroup = players.filter((p) => {
		return (
			p.modules &&
			p.modules.some((m) => {
				return (
					selectedModuleGroupData.moduleIds.indexOf(m.moduleId) >= 0 &&
					m.sessions &&
					m.sessions.length > 0
				);
			})
		);
	});

	return (
		<div className="AdminModuleStats">
			<div className="AdminModuleStats-players">
				<span>{getText(adminUiTexts.gameStatsPopup.totalPlayers, languageId)}: </span>
				<span>{playersInModuleGroup.length}</span>
			</div>
			<div className="AdminModuleStats-header">
				<div className="AdminModuleStats-cell task">
					<span>{getText(adminUiTexts.gameStatsPopup.task, languageId)}</span>
				</div>
				<div className="AdminModuleStats-cell type">
					<span>{getText(adminUiTexts.gameStatsPopup.type, languageId)}</span>
				</div>
				<div className="AdminModuleStats-cell errors">
					<span>{getText(adminUiTexts.gameStatsPopup.mistakes, languageId)}</span>
				</div>
				<div className="AdminModuleStats-cell perfect">
					<span>{getText(adminUiTexts.gameStatsPopup.perfectAttempts, languageId)}</span>
				</div>
				<div className="AdminModuleStats-cell attempts">
					<span>{getText(adminUiTexts.gameStatsPopup.attempts, languageId)}</span>
				</div>
				
			</div>
			<div className="AdminModuleStats-body">
				{selectedModuleGroupData.moduleIds.map((moduleId) => {
					const moduleData = modulesData.find((moduleData) => {return moduleData.id === moduleId;});

					return moduleData.tasks.map((taskData) => {
						if (!taskData.isSolveToContinue) return null;
						let numberOfErrors = 0;
						let numberOfPerfectAttempts = 0;
						let timesPlayed = 0;
						players.forEach(((player) => {
							const playerModuleData = getPlayerModuleData(moduleId, player);
							if (
								playerModuleData && 
								playerModuleData.sessions &&
								playerModuleData.sessions.length > 0
							) {
								playerModuleData.sessions.forEach((session) => {
									if (session.tasks) {
										const playerTaskData = session.tasks.find((t) => {
											return t.taskId === taskData.taskId;
										});
										if (playerTaskData && playerTaskData.isCompleted === true) {
											timesPlayed += 1;
											if (playerTaskData.hasOwnProperty('errors')) {
												numberOfErrors += playerTaskData.errors;
												if (playerTaskData.errors === 0) {
													numberOfPerfectAttempts += 1;
												}
											} else {
												numberOfPerfectAttempts += 1;
											}
										}
									}
								});
							}
						}));
						const avgErrors = (timesPlayed > 0 
							? (numberOfErrors / timesPlayed).toFixed(1)
							: '-'
						);
						const percentPerfectAttempts = (timesPlayed > 0
							? ((numberOfPerfectAttempts / timesPlayed) * 100).toFixed(1)
							: '-'
						);

						return ( 
							<div key={taskData.id} className="AdminModuleStats-row">  
								<div className='AdminModuleStats-cell task'>{taskData.id}</div>
								<div className='AdminModuleStats-cell type'>{taskData.type}</div>
								<div className='AdminModuleStats-cell errors'>{avgErrors}</div>
								<div className='AdminModuleStats-cell perfect'>{percentPerfectAttempts}</div>
								<div className='AdminModuleStats-cell attempts'>{timesPlayed}</div>
							</div>
						);		
					});

				})}
					
			</div>
		</div>
	);
};

AdminModuleStats.propTypes = {
	languageId: PropTypes.string.isRequired,
	selectedModuleGroupData: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired
};

export default AdminModuleStats;
