// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const cookiesUiTexts = {
	title: {
		da: 'Vi bruger cookies',
		en: 'We use cookies',
	},
	text: {
		da: `Vi bruger cookies til at forbedre din spiloplevelse. De håndterer din adgang spillet, og spillet fungerer derfor ikke uden.
			<br /><br />Tryk "OK" for at give os samtykke til at bruge cookies.
			<br /><br />Dit samtykke er gyldigt i et år. Du kan til hver en tid trække dit samtykke tilbage ved at klikke på ikonet nede i venstre hjørne.`,
		en: `We use cookies to improve your game experience. They handle your access to the game, and the game will not work without them. 
			<br /><br />Press "OK" to give us your consent to using cookies. 
			<br /><br />Your consent is valid for a year. You can at any time withdraw your consent by clicking on the icon in the bottom left corner. `,
	},
	okBtn: {
		da: 'OK',
		en: 'OK',
	},
	rejectBtn: {
		da: 'Afvis',
		en: 'Reject',
	}
};

module.exports = {
	cookiesUiTexts
};