import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import appConfig from 'config/app.config';
import {languagesData} from 'data/languages-data';
import Loading from 'components/loading/loading';
import ChooseLanguage from 'components/choose-language/choose-language';
import AdminController from 'components/admin/admin-controller';
import FacilitatorSelectGame from 'components/facilitator/facilitator-select-game';
import Facilitator from 'components/facilitator/facilitator';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			loadErrMsg: null,
			page: 'facilitator', // language, facilitator, admin
			prevPage: null,
			userData: null,
			games: [],
			players: [],
			surveys: [],
			gameId: null,
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGames = null;
		this.unsubscribeSurveys = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get facilitator data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Subscribe to games */
				this.subscribeToGames().then(() => {

					this.setState({isLoading: false});
					this.subscribeToSurveys();
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe  */
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		if (this.unsubscribeSurveys !== null) this.unsubscribeSurveys();
	};

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection('users').doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					const userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {
						/* Log last login date */
						const lastLogin = dayjs(new Date()).format('YYYY-MM-DD');
						this.updateUser({lastLogin: lastLogin});
						
						resolve({ status: 'success' });
					});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			}
			);
		});
	};

	/**
	 * Called by the facilitator to subscribe to all their games.
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName)
				.where('facilitatorEmails', 'array-contains', this.state.userData.email);
			this.unsubscribeGames = query.onSnapshot((querySnapshot) => {
				let games = [];
				querySnapshot.forEach((doc) => {games.push({id: doc.id, ...doc.data()});});
				this.setState({games: games}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get games: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Subscribe to surveys
	 */
	subscribeToSurveys = () => {
		if (this.unsubscribeSurveys !== null) this.unsubscribeSurveys();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			db.collection(appConfig.surveysDbName).onSnapshot((querySnapshot) => {
				let surveys = [];
				querySnapshot.forEach((doc) => {surveys.push({id: doc.id, ...doc.data()});});
				this.setState({surveys: surveys}, () => {resolve({status: 'success'});});
			},
			(error) => {
				console.error('could not get surveys: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Update player data
	 * @param {object} updates 
	 * @returns 
	 */
	updatePlayer = (playerId, updates) => {
		const db = firebase.firestore();
		const playerRef = db.collection('players').doc(playerId);
		return playerRef.update(updates);
	};

	/**
	 * Set / update prefered language
	 * @param {string} languageId 
	 */
	handleChooseLanguage = (languageId) => {
		const prevPage = (this.state.prevPage ? this.state.prevPage : 'facilitator');
		this.updateUser({languageId}).then(() => {
			this.setState({page: prevPage});
		}).catch(() => {
			this.setState({page: prevPage});
		});
	};

	/**
	 * Go to page ()
	 * @param {string} page 
	 */
	goToPage = (page) => {
		const prevPage = this.state.page;
		this.setState({page, prevPage});
	};

	handleSelectGame = (gameId = null) => {
		this.setState({gameId});
	};

	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading || !this.state.userData) {
			return (
				<Loading languageId={this.props.languageId} handleLogout={this.props.handleLogout} />
			);
		};

		/* Get language id (use browser language if user has not selected language) */
		const languageId = (
			this.state.userData && 
			this.state.userData.languageId && 
			languagesData.some((l) => {return l.id === this.state.userData.languageId;})
				? this.state.userData.languageId
				: this.props.languageId
		);

		if (!this.state.userData.languageId || this.state.page === 'language') {
			return (
				<ChooseLanguage 
					languageId={languageId} 
					role={'facilitator'}
					handleChooseLanguage={this.handleChooseLanguage} 
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		if (this.state.page === 'admin') {
			return (
				<AdminController 
					userData={this.state.userData}
					languageId={languageId}
					surveyResponses = {this.state.surveys}
					goToPage={this.goToPage}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		if (!this.state.gameId && this.state.games.length !== 1) {
			return (
				<FacilitatorSelectGame 
					userData={this.state.userData}
					languageId={languageId}
					games={this.state.games}
					handleChooseLanguage={this.handleChooseLanguage}
					handleSelectGame={this.handleSelectGame}
					goToPage={this.goToPage}
					handleLogout={this.props.handleLogout}
				/>
			);
		}

		/* Get data for selected game */
		const gameData = (this.state.games.length === 1
			? this.state.games[0]
			: this.state.games.find((g) => {return g.id === this.state.gameId;})
		);

		/* Get surveys for selected game */
		const gameSurveysResponses = (this.state.gameId 
			? this.state.surveys.filter((s) => {return s.gameId === this.state.gameId;})
			: this.state.games.length === 1
				? this.state.surveys.filter((s) => {return s.gameId === this.state.games[0].id;})
				: []
		);

		return (
			<Facilitator 
				userData={this.state.userData}
				languageId={languageId}
				numberOfGames={this.state.games.length}
				gameData={gameData}
				gameSurveysResponses={gameSurveysResponses}
				handleChooseLanguage={this.handleChooseLanguage}
				handleSelectGame={this.handleSelectGame}
				goToPage={this.goToPage}
				updatePlayer={this.updatePlayer}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	userId: PropTypes.string.isRequired,
	languageId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default FacilitatorController;