import React from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {
	getPlayerModuleData, 
	getNumberOfAttemptsAndErrors,
	checkIfModuleGroupIsCompleted,
} from 'helpers/module-helper';
import {getTimeSpentInModule} from 'helpers/time-helper';
import {scenariosData} from 'data/scenarios-data';
import AdminDownloadStats from '../admin-download-stats/admin-download-stats';
import './admin-general-stats.scss';

const AdminGeneralStats = ({isFullStats, languageId, gameId, gamesData, players, setSelectedModuleGroupId}) => {
	
	/* Get all scenarios (stats for all games) or only relevant scenario (stats for specific game) */
	let relevantScenarios = scenariosData;
	if (gameId) {
		const gameData = gamesData.find((g) => {return g.id === gameId;});
		const scenarioId = (gameData.scenarioId ? gameData.scenarioId : 'scenario-1');
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		relevantScenarios = [scenarioData];
	}
	
	return (
		<div className="AdminGeneralStats">
			<div className="AdminGeneralStats-players">
				<span>{getText(adminUiTexts.gameStatsPopup.totalPlayers, languageId)}: </span>
				<span>{players.length}</span>
			</div>
			{isFullStats && <div className="AdminGeneralStats-statsBtn">
				<AdminDownloadStats languageId={languageId} games={gamesData} players={players} />
			</div>}
			<div className="AdminGeneralStats-header">
				<div className="AdminGeneralStats-cell name">
					<span>{getText(adminUiTexts.gameStatsPopup.moduleGroup, languageId)}</span>
				</div>
				<div className="AdminGeneralStats-cell players">
					<span>{getText(adminUiTexts.gameStatsPopup.numberOfPlayers, languageId)}</span>
				</div>
				<div className="AdminGeneralStats-cell time">
					<span>{getText(adminUiTexts.gameStatsPopup.timeSpent, languageId)}</span>
				</div>
				<div className="AdminGeneralStats-cell errors">
					<span>{getText(adminUiTexts.gameStatsPopup.numberOfErrors, languageId)}</span>
				</div>
				<div className="AdminGeneralStats-cell completed">
					<span>{getText(adminUiTexts.gameStatsPopup.completed, languageId)}</span>
				</div>
			</div>
			<div className="AdminGeneralStats-body">
				{relevantScenarios.map((scenarioData) => {
					return scenarioData.moduleGroups.map((moduleGroup) => {
						const playersInModuleGroup = players.filter((p) => {
							return (
								p.modules &&
								p.modules.some((m) => {
									return (
										moduleGroup.moduleIds.indexOf(m.moduleId) >= 0 &&
										m.sessions &&
										m.sessions.length > 0
									);
								})
							);
						});
						let numberOfAttempts = 0;
						let numberOfErrors = 0;
						let timePlayed = 0;
						moduleGroup.moduleIds.forEach((moduleId) => {
							playersInModuleGroup.forEach((playerData) => {
								const playerModuleData = getPlayerModuleData(moduleId, playerData);
								if (playerModuleData) {
									const {attempts, errors} = getNumberOfAttemptsAndErrors(playerModuleData);
									numberOfAttempts += attempts;
									numberOfErrors += errors;
									timePlayed += getTimeSpentInModule(playerModuleData);
								}
							});
						});
						let numberOfPlayersCompletedModuleGroup = 0;
						playersInModuleGroup.forEach((playerData) => {
							if (checkIfModuleGroupIsCompleted(moduleGroup.moduleIds, playerData)) {
								numberOfPlayersCompletedModuleGroup += 1;
							}
						});

						let avgTimePlayed = 0;
						if (playersInModuleGroup.length > 0) {
							avgTimePlayed = (timePlayed / playersInModuleGroup.length).toFixed(1);
						}
						let avgErrors = 0;
						if (numberOfAttempts > 0) {
							avgErrors = (numberOfErrors / numberOfAttempts).toFixed(2);
						}

						return (
							<div 
								key={moduleGroup.id} 
								className="AdminGeneralStats-row"
								onClick={() => {setSelectedModuleGroupId(moduleGroup.id);}}
							>
								<div className="AdminGeneralStats-cell name">
									<span>{getText(moduleGroup.title, languageId)}</span>
								</div>
								<div className="AdminGeneralStats-cell players">
									<span>{playersInModuleGroup.length}</span>
								</div>
								<div className="AdminGeneralStats-cell time">
									<span>{avgTimePlayed + ' ' 
										+ getText(adminUiTexts.gameStatsPopup.minutes, languageId)}
									</span>
								</div>
								<div className="AdminGeneralStats-cell errors">
									<span>{avgErrors}</span>
								</div>
								<div className="AdminGeneralStats-cell completed">
									<span>{numberOfPlayersCompletedModuleGroup}</span>
								</div>
							</div>
						);
					});
				})}
			</div>
		</div>
	);
};

AdminGeneralStats.propTypes = {
	isFullStats: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string,
	gamesData: PropTypes.array.isRequired,
	players: PropTypes.array.isRequired,
	setSelectedModuleGroupId: PropTypes.func.isRequired
};

export default AdminGeneralStats;
