const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Velkommen til første del af hygiejnetræningen. Jeg er din mester og hjælper dig gennem spillet. `,
			en: `Welcome to the first part of the hygiene training. I'm your manager and I will help you through the game. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-2',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1'],
		text: {
			da: `Træningen her handler om, hvordan du kan være med til at opretholde vores høje standard. Du skal arbejde med fødevarer, og der er fødevaresikkerhed og kvalitet i højsædet. `,
			en: `This training is all about how you can help us maintain our high standards. You are working with foodstuffs, so food safety and quality is paramount. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-3',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `I spillet får du point for at løse opgaver. Jo færre fejl du laver, jo flere point får du. `,
			en: `In the game you get points for solving tasks. The fewer mistakes you make, the more points you get.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-stars',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Når du får point, kan du også få stjerner. Du skal have tre stjerner for at gå videre til næste modul. `,
			en: `When you get points, you also get stars. You need three stars to move on to the next module.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-correct-clothing',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2'],
		text: {
			da: `Første opgave handler om den rigtige påklædning. `,
			en: `The first task is about the proper attire.`,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-clothing-for-production',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `I hvilken rækkefølge skal du klæde dig på til at arbejde i produktionen? Sæt handlingerne i den rigtige rækkefølge. Tryk ok, når det er rigtigt. `,
			en: `In what order should you get dressed for work in production? Put the actions in the right order. Press ok when it is correct.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Hårnet og eventuelt skægbind`,
					en: `Hair net and (if needed) beard snood`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Bukser`,
					en: `Pants`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Busseronne`,
					en: `Smock`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Sko eller støvler`,
					en: `Shoes or boots`
				},
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hairnet',
		layout: 's1-m1-hairnet',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvordan skal hårnet og skægbind sidde? Tryk på de rigtige billeder.`,
			en: `How should you correctly wear hair net and beard mask? Click on the correct pictures.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Billede af alt hår og ører dækket af hårnet. Ingen skæg. 
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Billede af alt hår, ører og alt skæg dækket. 
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis du er nybarberet, behøver du ikke bære skægbind. `,
								en: `If you have shaved today, you don't have to wear a beard snood.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Noget hår stikker ud. Intet skæg. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Alt hår dækket. Skæg. Ingen skægbind. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Alt hår men ikke ører dækket. Intet skæg. 
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			da: `Hvilke smykker må du IKKE have på i produktionslokaler?`,
			en: `What kind of jewelry are you NOT allowed to wear in production areas?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Glat vielsesring uden sten - husk at fjerne highlight
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Næsepiercing
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navlepiercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Armbåndsur
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Halskæde
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Briller med smykkesten
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Ørering
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Rigtigt. Heller ikke selvom du har et hårnet ned over. `,
								en: `Right. Not even if you wear it under your hair net.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8, // Tandsmykke
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-good-hygiene',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `God hygiejne starter allerede i omklædningsrummet. Prøv at se, hvilke problemer du kan spotte på næste billede. `,
			en: `Good hygiene begins in the changing room. Try to see which problems you can spot on the next screen.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-open-closet',
		layout: 's1-m1-open-closet',
		background: 'dressing-room',
		text: {
			da: `Tryk på de 5 ting, som er forkerte på billedet. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle.`,
			en: `Click on the 5 things that are wrong in the picture. Click OK when you are ready, or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'glove', // Skærehandske
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'knife' // Slagtekniv blåt håndtag
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hairnet', // Hårnet
				feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
					text: {
						da: `Godt set. Det skal nemlig bare smides ud.`,
						en: `Nice catch! Just throw that in the bin. `
					}
				})
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'steel', // Strygestål blåt håndtag
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'smock', // Hvid kittel
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-rules-still-count',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Nogle steder har vi puljetøj, men reglerne om ikke at blande privat tøj og arbejdstøj gælder stadig.`,
			en: `Some places we have shared batch clothes, but the rule about not mixing private clothes and work clothes still apply. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-not-allowed-dressing-cabinet',
		layout: 's1-m1-not-allowed-dressing-cabinet',
		subtype: 'images',
		background: 'dressing-room',
		text: {
			da: `Hvad må IKKE opbevares i omklædningsrummet?`,
			en: `What items are NOT allowed in the changing rooms?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Kniv
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Blåt forklæde
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Termosokker
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Håndklæde
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-storage-avoid-contamination',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Ved at opbevare ting de rigtige steder undgår du, at værktøj, arbejdstøj og skabe bliver forurenet. `,
			en: `By storing things in the right places, you avoid getting work clothes and lockers contaminated. `,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-one-more-look',
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Lad os lige kigge en sidste gang på påklædning. Find fejlene på den næste skærm. `,
			en: `Let's take one last look at clothing. Find the mistakes on the next screen.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-person',
		layout: 's1-m1-person',
		background: 'dressing-room',
		backgroundModifier: 'lockers-hygiene-lock',
		text: {
			da: `Tryk på de 4 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 4 mistakes. Click OK when you are ready, or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'watch', // Ur på venstre hånd.
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair' // Hår stikker ud under hårnet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt', // Snavs på skoene
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'necklace', // halskæde under kraven på buseronne eller ørering under hårnet
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-almost-production',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `Godt, så er vi ved at være klar til at gå ind i produktionen. Der møder du altid en hygiejnesluse. Lad os lige kigge nærmere på dén. `,
			en: `Good, now we're about ready to go into the production. There you will always meet a hygiene sluice. Let's take a closer look at it. `,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-hygiene-slouse',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `I hygiejnesluser skal du altid vaske hænder. Hvad er den rigtige rækkefølge at gøre tingene i?`,
			en: `In hygiene sluice, you must always wash your hands. What is the correct order of operations when doing that?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Vask hænder`,
					en: `Wash hands`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Tør hænder`,
					en: `Dry hands`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Smid håndklædepapir i skraldepose`,
					en: `Throw paper towel in the bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Brug håndsprit`,
					en: `Use sanitiser`
				},
				correctOrderNumbers: [4]
			})
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-hand-rubbing-alcohol',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `Bemærk, at du altid skal tage håndsprit på, når du går ind.`,
			en: `Note, always apply sanitiser when entering. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Håndhygiejne handler også om neglene.
				<br /><br />Tryk på de forkerte billeder. `,
			en: `The nails are an important part of hand hygiene. 
				<br /><br />Click on the images that are wrong.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Korte og rene negle
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // korte med neglelak
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Korte let beskidte negle
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Lange let beskidte negle
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Kunstig negl med nail art
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Lang ren negl
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hands',
		layout: 's1-m1-hands',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Der er især tre områder, som er nemme at glemme under håndvask. Hvilke er det?`,
			en: `Three areas are especially easy to forget when washing your hands. What are those?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Håndfladen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Håndryggen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Neglene
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Håndled
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Mellem fingre
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Tommelfinger
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-to-wash-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		text: {
			da: `Efter hvilke situationer skal du huske at vaske hænder? Husk at læse alle mulighederne.`,
			en: `After which situations must you remember to wash your hands?  Remember to read all the options.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Når du passerer en hygiejnesluse`,
					en: `When passing through a hygiene sluice`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Efter pauser`,
					en: `After breaks`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hvis du samler noget op fra gulvet`,
					en: `If you pick up something from the floor`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Efter at binde snørebånd`,
					en: `After tying my shoelaces`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Efter du har rørt ved din mund og næse`,
					en: `After touching your mouth or nose`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Efter brug af stiger`,
					en: `After using ladders`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: {
					da: `I alle ovenstående situationer `,
					en: `In all above situations`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-before-production',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `Inden vi går ind i produktionen er der et vigtigt spørgsmål om sygdom. `,
			en: `Before we enter the production area, we have an imporant question about disease. `,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m1-survey-1',
		shuffleOptions: false,
		backgroundModifier: 'hygiene-lock',
		text: {
			da: `Er du bekendt med følgende?
				<br />Du har ansvar for at informere din mester, hvis du eller én fra din husstand får en smitsom sygdom som for eksempel diarré eller opkast.`,
			en: `Are you aware of the following?
				<br />You are responsible for informing your manager if you or someone in your household contracts a contagious disease such as diarrhea or vomiting.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Ja, det er jeg klar over`,
					en: `Yes, I am aware of that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt. Hvis vi ved den slags, så kan vi bedre sikre en høj hygiejnestandard. `,
								en: `That's good. If we know things like that, we can better ensure a high hygiene standard.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Nej, det vidste jeg ikke`,
					en: `No, I did not know that`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt du siger det. Når du er færdig med træningen, så tal straks med din mester eller kursusleder og få styr på det.`,
								en: `A good thing that you tell us. After completing this training, immediately talk to your manager to get things sorted out. `
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-well-done',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Godt gået! Du er nu færdig med første modul. Hvis du har fået tre stjerner, kan du fortsætte til næste modul. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `Well done! You have completed the first module. If you have three stars, you can continue to the second part of the training. If not, you have to take this module once more. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};