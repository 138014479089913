import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './module-nav.scss';

const ModuleNav = ({moduleData, taskId, handleGoToTask}) => {
	
	/* Track if open / closed */
	const [isOpen, setIsOpen] = useState(false);

	/* Get current task index */
	const selectedTaskIndex = moduleData.tasks.findIndex((task) => {return task.id === taskId;});

	/* On open, autoscroll to current task on list */
	useEffect(() => {
		if (isOpen) {
			const taskElements = document.getElementsByClassName('ModuleNav-item selected');
			if (taskElements) {
				taskElements[0].scrollIntoView(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div className={'ModuleNav' + (isOpen ? ' open' : '')} onClick={() => {setIsOpen(!isOpen);}}>
			{isOpen && <div className="ModuleNav-items">
				{moduleData.tasks.map((task, index) => {
					return (
						<div 
							key={task.id} 
							className={'ModuleNav-item' + 
								(index < selectedTaskIndex ? ' prev' : '') +
								(task.id === taskId ? ' selected' : '')
							}
							onClick={() => {handleGoToTask(task.id);}}
						>{task.id} <span>({task.type + (task.subtype ? ' ' + task.subtype : '')})</span></div>
					);
				})}
			</div>}
			<div className="ModuleNav-toggleBtn" />
		</div>
	);
};

ModuleNav.propTypes = {
	moduleData: PropTypes.object.isRequired,
	taskId: PropTypes.string.isRequired,
	handleGoToTask: PropTypes.func.isRequired,
};

export default ModuleNav;
