import appConfig from 'config/app.config';

/**
 * 
 * @param {string} userId
 * @returns 
 */
export function checkIfValidUserId(userId) {
	let isValid = true;
	let feedback = null;

	if (!userId || userId.length === 0) {
		/* No input */
		isValid = false;
		feedback = 'emptyUserId';
	} else {
		/* Check format, user id must be 4-7 digits */
		const isNumbersOnly = userId.toLowerCase().match(/^\d+$/);
		if (
			!isNumbersOnly || 
			userId.length > appConfig.maxEmployeeIdLength || 
			userId.length < appConfig.minEmployeeIdLength
		) {
			isValid = false;
			feedback = 'invalidUserId';
		}
	}
	return {isValid, feedback};
}