import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBZcBnZ4Gbnd3rX8mc_4V8SvjZuESzgIWo',
		authDomain: 'cgl-beef-test.firebaseapp.com',
		projectId: 'cgl-beef-test',
		storageBucket: 'cgl-beef-test.appspot.com',
		messagingSenderId: '704665412294',
		appId: '1:704665412294:web:80590dbe951eae95ae6dc7'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDx1mOxf-b9DFCDGhm-FjqpiFpZDab3G5A',
		authDomain: 'cgl-beef-production.firebaseapp.com',
		projectId: 'cgl-beef-production',
		storageBucket: 'cgl-beef-production.appspot.com',
		messagingSenderId: '595055885556',
		appId: '1:595055885556:web:22491895da951bd9a4f2a1'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;