const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-welcome-to-fourth-part',
		showProgressBar: false,
		background: 'dressing-room',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Velkommen til anden del af hygiejnetræningen. Nu skal det handle om hygiejne i produktionen. `,
			en: `Welcome to the second part of the hygiene training. Now we will focus on hygiene in production areas.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-its-about-products-in-production',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Det handler om, hvordan du skal håndtere produkter i produktionen. `,
			en: `It is about how you should handle the products in the production areas.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-lets-begin',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `Lad os kaste os ud i det. Husk, du skal have tre stjerner for at gennemføre. `,
			en: `Let's dive into it. Remember you need three stars to complete the module.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-extra-attentive',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Hvorfor skal vi være ekstra opmærksomme på hygiejne i produktionen?`,
			en: `Why should we pay extra attention to hygiene in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `På grund af lovkrav`,
					en: `Because of legal requirements`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fordi dårlig hygiejne kan gøre folk syge`,
					en: `Because bad hygiene can make people sick`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `For at sikre høj kvalitet`,
					en: `To ensure high quality`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Af alle ovenstående grunde`,
					en: `For all above reasons`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-lets-closer-look-production',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Lad os kigge nærmere på produktionen. På næste billede skal du spotte de ting, som er problematiske i forhold til hygiejne.`,
			en: `Let's take a closer look at the production. On the next picture, you must spot the things that are problematic in terms of hygiene.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's2-m2-spot-errors-meat',
		layout: 's2-m2-meat',
		background: 'production',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 5 mistakes. Click OK when you are ready, or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'meat', // Bakker til produkter står på gulvet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'cardboard' // Sammenfoldet papkasse
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'display', // kød på display
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tools', // Fødevareemballage med værktøj i
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set. Og ud over du ikke må stille værktøj i vores emballage, så må du heller ikke medbringe træværktøj. `,
								en: `Nice catch! You may never place tools in our boxes and crates, and neither may you bring tools with wood in them.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 't-bone', // Produkt T-bone steak på gulvet
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-T-bone-steak-floor',
		background: 'production',
		text: {
			da: `Der lå en T-bone steak på gulvet i den forrige opgave – hvad skal du gøre med den?`,
			en: `There was a T-bone steak on the floor in the previous task. What should you do about it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Smide den ud i almindelig skraldespand`,
					en: `Toss it in a regular bin`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kasseres, så den kommer i det grå kar til biogas`,
					en: `Discard it into the grey tub for biogas`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Lægge den tilbage på båndet `,
					en: `Put it back on the line`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-T-bone-steak-floor-b',
		background: 'production',
		text: {
			da: `I sidste opgave fandt du ud af, at du ikke må lægge T-bone steaken tilbage på båndet. Hvorfor må du ikke det? `,
			en: `In the previous task you found out that you mustn't put the T-bone steak back on the production belt. Why not?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan forurene de efterfølgende produkter`,
					en: `It can contaminate the following products `
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det er ligegyldigt, steaken må gerne komme tilbage på båndet`,
					en: `It doesn't matter, I can put the steak back on the line`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Der er risiko for at forbrugerne kan blive syge`,
					en: `There's a risk of consumers getting sick`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Kødet er blevet sejt og kan ikke bruges`,
					en: `The meat has become tough and cannot be used`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Der er risko for fremmedlegemer`,
					en: `There's a risk of foreign bodies`
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-you-close-to-production',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Du er tæt på produktionen hver dag, og derfor lægger du garanteret mærke til ting, som andre overser. Og hvis du ser noget, så husk at reagere. `,
			en: `You are close to the production every day and therefore you are guaranteed to notice things that others overlook. And if you see something, remember to act on it. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-correct-images',
		layout: 's2-m2-correct-images',
		subtype: 'images',
		background: 'production',
		text: {
			da: `Hvilke billeder er korrekte?`,
			en: `Which images are correct?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Vogn der er overfyldt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Bakke der er fyldt til under kanten
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Bakke der er fyldt med top
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Vogn fyldt lige til kanten
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-no-contact-clothes',
		layout: 's1-m2-clothes-dont-touch',
		subtype: 'images',
		background: 'production',
		text: {
			da: `Det er vigtigt, at dit tøj ikke kommer i berøring med uemballerede produkter. Hvad skal du bruge eller gøre for at undgå det?`,
			en: `It is important that your clothes do not come into contact with unpackaged products. What should you use or do to avoid it?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Forklæde
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Ærmeskåner
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Oprullede ærmer
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Hawaii-skjorte
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Plastikposer på armene
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Skudsikker vest
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-lets-look-at-contamination',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Nu skal vi kigge lidt på forurening. Det handler grundlæggende om, at der kommer ting i produkterne, som ikke skal være der. `,
			en: `Now let's take a look at contamination. It's basically about things getting into the products that shouldn't be there.`,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's2-m2-order-oil-spot',
		background: 'production',
		text: {
			da: `Du ser en olieplet på båndet. Der er risiko for, at produkter er blevet forurenet. Hvad skal du gøre? Sæt nedenstående handlinger i den rigtige rækkefølge. `,
			en: `You notice an oil stain on the line. There's a risk that products have been contaminated. What should you do? Place the actions below in the right order. `
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Tilkald mester`,
					en: `Call for a manager`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Afvent frigivelse fra mester`,
					en: `Await release from manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Fortsæt arbejdet`,
					en: `Continue work`
				},
				correctOrderNumbers: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-abscess-on-meat',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Hvad hvis du ser en byld på kødet, hvad skal du så gøre?`,
			en: `If you see a boil on the meat, what should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Send til renskæring`,
					en: `Send to final trimming`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Hvis bylden har forurenet arbejdsområdet, så skal du stoppe produktionen og rengøre. `,
								en: `If the boil has contaminated your work area, you must stop production and clean it. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Skift udstyr`,
					en: `Change equipment`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Vask båndet med vandslange`,
					en: `Wash the line with a hose`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Stoppe produktionen`,
					en: `Stop production`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-products-contamination',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Vores produkter kan blive forurenet på forskellige måder. Hvorfor er forurening et problem?`,
			en: `Our products can become contaminated in various ways. Why is contamination an issue?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Det kan være farligt`,
					en: `It can be dangerous`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det kan ødelægge smagen`,
					en: `It can ruin the taste`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det kan give utilfredse kunder`,
					en: `It can result in dissatified customers`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Det udleder mere CO2`,
					en: `It emits more CO2`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Forurening er kun et problem, hvis andre ser det`,
					en: `Contamination is only a problem if others see it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Det kan gøre vores tøj beskidt`,
					en: `It can make our clothes dirty`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-procedure-can-differ-type-contamination',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Alt efter typen af forurening, så kan proceduren kan være forskellig. Lad os tage et andet eksempel.`,
			en: `Depending on the type of contamination, the correct procedure can vary. Let's look at another example. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-plastic-piece-on-meat',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Du ser et stykke plast i noget kød på båndet. Hvad skal du gøre?`,
			en: `You notice a piece of plastic in some meat on the line. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Fjern plaststykket`,
					en: `Remove the plastic`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Tilkald mester`,
					en: `Call for a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Ja! Mester kan vurdere om produktionen skal standses. `,
								en: `Yes! Your manager can decide whether or not to stop production.`
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Stands produktionen`,
					en: `Stop production`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Smid kødet til destruktion`,
					en: `Send the meat for destruction`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Smid kødet til dyrefoder`,
					en: `Send the meat to animal feed`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-missing-finger-glove',
		background: 'production',
		shuffleOptions: false,
		text: {
			da: `Du opdager, at fingerspidsen mangler på en af dine plasthandsker. Hvad skal du gøre?`,
			en: `You notice that the tip of your plastic glove is missing. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Informere min mester med det samme, så plaststykket kan findes`,
					en: `Inform my manager right away, so the piece of plastic can be found`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fortsætte arbejdet. Jeg kan skifte handske i næste pause`,
					en: `Continue work. I can change glove in my next break`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Skifte handske så jeg kan arbejde videre`,
					en: `Change glove so I can continue work`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Jeg behøver slet ikke skifte handske`,
					en: `I don't have to change my glove`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-contamination-allergy',
		layout: 's1-m2-contamination-allergy',
		subtype: 'images',
		background: 'production',
		text: {
			da: `Forurening kan også være et problem for folk med allergi. Hvad må du aldrig tage med på slagteriet - heller ikke i madpakken?`,
			en: `Contamination can also be a problem for people with allergies. What are you never allowed to bring to the factory - not even in your lunch?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Nutella
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Peanuts og hasselnødder
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Jordbær
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Rejer
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Sesambolle
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Det er specifikt sesamfrøene, som ikke er tilladte. De kan give folk, som er allergiske, en voldsom reaktion. `,
								en: `It's the sesame seeds that aren't allowed. They can cause severe reaction in people who are allergic.`
							}
						})
					})
				],
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-good-hygiene-starts-with-you',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `God hygiejne og dermed høj kvalitet starter med dig. Se om du kan spotte problemerne på næste billede.`,
			en: `Good hygiene and high quality starts with you. See if you can spot the problems in the next picture.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's2-m2-spot-errors-production-errors',
		layout: 's2-m2-production-errors',
		background: 'production',
		backgroundModifier: 'open-gate',
		text: {
			da: `Tryk på de 5 fejl. Tryk OK, når du er klar, eller hvis du ikke kan finde dem alle. `,
			en: `Click on the 5 issues. Click OK when you are ready, or if you can't find them all. `
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'drops', // Dråbe hænger ned fra rør over produktionsbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gloves' // Handsker ligger oven på et produkt
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'oil', // Olieplet på transportbånd
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'thread', // Tråd fra tøj sidder på noget produkt
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'gate', // Port står åben
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-wash-floor',
		background: 'production',
		text: {
			da: `Hvis du skal vaske gulvet, hvad skal du så gøre?`,
			en: `If you need to wash the floor, what should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Bruge lavt tryk`,
					en: `Use low pressure`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop! Og hvis I har en såkaldt vaskehund, så skal du bruge den.`,
								en: `Exactly! And if you have a VASKEHUND, use that also. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Vaske væk fra kødet`,
					en: `Wash away from the meat`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Bruge højt tryk`,
					en: `Use high pressure`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Også vaske kødet`,
					en: `Wash the products too`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-while-floor-washing',
		background: 'production',
		text: {
			da: `Mens du vasker gulv, kommer du til at sprøjte vand op på nogle af produkterne. Hvorfor er det et problem?`,
			en: `While washing the floor, you accidentially spray some water on the meat. Why is that a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Der kan komme bakterier fra gulvet op på produkterne`,
					en: `Bacteria from the floor can end up in products`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Kød kan ikke tåle vand`,
					en: `Meat doesn't tolerate water`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det er faktisk ikke et problem`,
					en: `Actually, it's not a problem`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-wet-meat',
		background: 'production',
		text: {
			da: `Hvad skal der ske med det kød, du er kommet til at sprøjte vand op på?`,
			en: `What should you do about the meat you sprayed with some water?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Jeg skal vaske det`,
					en: `I must wash it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Det skal til reinspektion`,
					en: `Send it to reinspection`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Det skal til destruktion`,
					en: `Send it to destruction`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Der skal ikke ske noget`,
					en: `Nothing`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-cleaning-agent',
		background: 'production',
		text: {
			da: `Du ser en dunk med rengøringsmiddel stå ved din plads i produktionen. Hvad gør du?`,
			en: `You see a bottle of cleaning agent near your work station. What do you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Lader det stå`,
					en: `Leave it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Fjerner det og tilkalder så mester`,
					en: `Remove it and call for a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt! Vi skal sikre os, at der ikke er kommet noget i produkterne. `,
								en: `Yes. We must make sure that it hasn't contaminated our products. `
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Tilkalder mester`,
					en: `Call for a manager`
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Altid godt at spørge, men husk også at fjerne dunken.`,
								en: `It's always good to ask. But don't forget to remove the bottle too. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Fjerner det`,
					en: `Remove it`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-if-in-doubt',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Hvis du er i tvivl om, hvorvidt noget er blevet forurenet, så spørg din mester. `,
			en: `If you are in doubt as to whether something has been contaminated, ask your manager.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-avoid-pests',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-2'],
		text: {
			da: `Vi skal undgå skadedyr i produktionen.`,
			en: `We must avoid pests in the production.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-spot-mouse',
		background: 'production',
		text: {
			da: `Du spotter en mus på gulvet i produktionen. Hvad skal du gøre?`,
			en: `You spot a mouse on the production floor. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Forsøge at holde øje med musen imens jeg tilkalder hjælp`,
					en: `Try to keep an eye on the mouse while I call for help`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Forsøge at fange musen selv og derefter tilkalde hjælp, hvis det ikke kan lade sig gøre`,
					en: `Try to catch the mouse yourself and then call for help if that is not possible`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Sige det til nærmeste leder efter endt arbejdsdag`,
					en: `Tell my immediate manager after the end of the work day`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Give den et stykke kød`,
					en: `Give it a piece of meat`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-control-guests',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Det er vigtigt, at vi altid har styr på vores gæster. Folk udefra har ikke din træning, så de kan nemt udgøre en risiko i forhold til hygiejne. `,
			en: `It's important to know who our guests are. Outsiders don't have your training, so they can be a risk when it comes to hygiene. `,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-guests',
		layout: 's1-m2-guests',
		subtype: 'images',
		background: 'production',
		text: {
			da: `Hvad skal gæster altid bære?`,
			en: `What must guests always wear?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hvid overtræksdragt
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Sydvest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Rumdragt
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Paraply
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Blåt forklæde
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's2-m2-mc-see-guests-seeking',
		background: 'production',
		text: {
			da: `Hvad skal du gøre, hvis du ser en, der ser søgende ud eller ikke har dragten fra forrige opgave på.`,
			en: `What should you do if you notice someone who looks lost or isn't wearing the suit from the previous task?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Spørg vedkommende om deres ærinde`,
					en: `Ask the person about their business`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Gå til mester`,
					en: `Inform a manager`
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Råbe på hjælp`,
					en: `Cry for help`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Ringe efter politiet`,
					en: `Call the police`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Vise dem hen til receptionen`,
					en: `Show them to the reception`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-four-questions',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Til allersidst er der fire spørgsmål om fødevaresikkerhedskulturen på Danish Crown Beef. Her er der ikke rigtige og forkerte svar. Spørgsmålene giver ikke point, og besvarelserne er anonyme. `,
			en: `Finally, we have four questions about the food safety culture at Danish Crown Beef. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously. `,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-1',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Min leder beder mig altid om at følge reglerne.`,
			en: `How strongly do you agree with the following statement?
				<br />My manager always tells me to follow the rules.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-2',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Jeg reagerer, hvis jeg ser andre, der ikke følger hygiejnereglerne?`,
			en: `How strongly do you agree with the following statement?
				<br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-3',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg ser et fødevaresikkerhedsproblem, så løser jeg det eller informerer min nærmeste leder?`,
			en: `How strongly do you agree with the following statement?
				<br />If I see a food safety issue, I'll either solve it or inform my immediate manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's2-m2-survey-4',
		shuffleOptions: false,
		text: {
			da: `Hvor enig er du i følgende: 
				<br />Hvis jeg rapporterer en risiko for fødevaresikkerheden, så bliver der gjort noget ved det?`,
			en: `How strongly do you agree with the following statement?
				<br />If I report a food safety risk, something will be done about it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Meget enig`,
					en: `Strongly agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `Enig`,
					en: `Agree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hverken enig eller uenig`,
					en: `Neither agree nor disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Uenig`,
					en: `Disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					da: `Meget uenig`,
					en: `Strongly disagree`
				},
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					da: `Ved ikke`,
					en: `Don't know`
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's2-m2-instructions-done',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-2'],
		text: {
			da: `Godt gået! Du er nu færdig med andet modul. Hvis du har fået tre stjerner, er du færdig med uddannelsen for denne gang. Hvis ikke, så må du tage det her modul en gang til. `,
			en: `Well done! You have completed the second module. If you have three stars, you have completed the training. If not, you have to retake the module. `,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's2-m2-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};