/**
 * Get game url from full url
 * @param {string} pathname 
 * @returns {string} gameUrl
 */
export function getGameUrl(pathname) {
	
	let gameUrl = null;

	/* Get url path (remove starting/trailing '/', convert to lowercase) */
	if (pathname.length > 1) {
		gameUrl = pathname.slice(1).toLowerCase();
		if (gameUrl.charAt(gameUrl.length - 1) === '/') gameUrl = gameUrl.slice(0, gameUrl.length - 1);
	}

	return gameUrl;
}