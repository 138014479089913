import React from 'react';
import PropTypes from 'prop-types';
import './image.scss';

const Image = ({languageId, taskData}) => {
	return (
		<div className={'Image ' + languageId + (taskData.layout ? ' ' + taskData.layout : '')} />		
	);
};

Image.propTypes = {
	languageId: PropTypes.string.isRequired,
	taskData: PropTypes.object.isRequired,
};

export default Image;
