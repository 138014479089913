import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getModuleMaxPoints, getNumberOfFilledStars} from 'helpers/points-helper';
import {starsPerPercentPoints} from 'data/points-data';
import Star from 'components/ui/star/star';
import './progress-bar.scss';

const ProgressBar = ({moduleId, points}) => {
	/* Get max points for module */
	const maxPoints = getModuleMaxPoints(moduleId);

	/* Convert points to percent of max points */
	const percentPoints = points / maxPoints * 100.;

	/* Get number of filled stars */
	const filledStars = getNumberOfFilledStars(points, maxPoints);

	return (
		<div className="ProgressBar">
			<div className="ProgressBar-track">
				<div className="ProgressBar-progress" style={{width: percentPoints + '%'}} />
			</div>
			<div className="ProgressBar-stars">
				{[...Array(appConfig.maxStarsPerModule)].map((_, index) => {
					const leftPos = starsPerPercentPoints.pointSteps[index + 1] + '%';
					return (
						<div key={index} className="ProgressBar-star" style={{left: leftPos}}>
							<Star page="progress" isFilled={index < filledStars} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

ProgressBar.propTypes = {
	moduleId: PropTypes.string.isRequired,
	points: PropTypes.number.isRequired,
};

export default ProgressBar;
