import dayjs from "dayjs";

/* Player data */
const playerDataTemplate = {
	name: null,
	email: null,
	languageId: null,
	started: dayjs(new Date()).format('YYYY-MM-DD'),
	gameId: null,
	modules: [],
	
};

export {playerDataTemplate};
