import appConfig from 'config/app.config';
import {scenariosData} from 'data/scenarios-data';

/**
 * Get all surveys of a game scenario
 * @param {object} scenarioIds 
 * @returns 
 */
const getAllSurveysOfScenarios = (scenarioIds) => {
	let surveys = [];

	scenarioIds.forEach((scenarioId) => {
		const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
		const modulesData = scenarioData.modulesData ? scenarioData.modulesData : [];
	
		/* Get all surveys of each module */
		modulesData.forEach((moduleData) => {
			if (moduleData.tasks && moduleData.tasks.length > 0) {
				const moduleSurveys = moduleData.tasks.filter((t) => {return t.type === 'survey';});
				if (moduleSurveys.length > 0) {
					surveys.push(...moduleSurveys);
				}
			}
		});
	});
	
	return surveys;
};


/**
 * Get survey results
 * @param {*} surveyId 
 * @param {*} surveysData 
 * @param {*} gameSurveysResponses 
 */
const getSurveyResults = (surveyId, surveysData, gameSurveysResponses) => {
	let surveyResults = null;

	const surveyData = surveysData.find((s) => {return s.id === surveyId;});
	const surveyResponses = gameSurveysResponses.filter((s) => {return s.taskId === surveyId;});
	if (
		surveyResponses.length >= appConfig.minSurveyParticipants && 
		surveyData.options && 
		surveyData.options.length > 0
	) {
		
		surveyResults = {
			numberOfResponses: surveyResponses.length,
			options: surveyData.options.map((option) => {return {id: option.id, answers: 0};})
		};
		surveyResponses.forEach((response) => {
			if (response.selectedOptionIds && response.selectedOptionIds.length > 0) {
				response.selectedOptionIds.forEach((optionId) => {
					const optionIndex = surveyResults.options.findIndex((o) => {return o.id === optionId;});
					if (optionIndex >= 0) {
						surveyResults.options[optionIndex].answers += 1;
					}
				});
			}
		});
	}


	return surveyResults;
};

export {
	getAllSurveysOfScenarios,
	getSurveyResults
};
