const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			}
		],
	},
	{
		id: 'dressing-room',
		modifiers: [
			{
				id: 'lockers',
			},
			{
				id: 'hygiene-lock'
			},
			{
				id: 'lockers-hygiene-lock'
			},
			{
				id: 'no-bench'
			},
			{
				id: 'zoom-in'
			},
			{
				id: 'zoom-in-no-bench'
			}
		]
	},
	{
		id: 'pilot-plant',
		modifiers: [
			{
				id: 'none',
			},
			{
				id: 'moved-left',
			}
		]
	},
	{
		id: 'production',
		modifiers: [
			{
				id: 'none',
			},
			{
				id: 'open-gate'
			}
		]
	}
];

export {backgroundsData};