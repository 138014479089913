import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

/**
 * Get audio src
 * @param {fileName} languageId 
 */
export function getAudioSrc(fileName) {
	// const languageFolder = languageId;
	// const moduleFolder = moduleId;
	let filePath = null;
	if (fileName) {
		filePath = 'audio/' + fileName + '.mp3';
	}


	/* Get link to audio file */
	return new Promise((resolve) => {
		if (!filePath) resolve({status: 'error', error: 'no-such-file'});

		const storageRef = firebase.storage().ref();
		storageRef.child(filePath).getDownloadURL()
			.then((src) => {resolve({status: 'success', src: src});})
			.catch((error) => {resolve({status: 'error', error: error});});
	});
}