import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Button from 'components/ui/button/button';
import AdminGeneralStats from './admin-generel-stats/admin-general-stats';
import AdminModuleStats from './admin-module-stats/admin-module-stats';
import './admin-game-stats-popup.scss';

const AdminGameStatsPopup = (props) => {
	const {
		languageId,
		gameId, 
		gamesData, 
		playersData, 
		handleToggleGameStats
	} = props;

	/* Selected module id */
	const [selectedModuleGroupId, setSelectedModuleGroupId] = useState(null);

	/* Get game data */
	const gameData = (gameId 
		? gamesData.find((game) => {return game.id === gameId;})
		: null
	);

	/* Get module groups for all scenarios */
	const moduleGroups = [];
	scenariosData.forEach((scenarioData) => {
		scenarioData.moduleGroups.forEach((moduleGroup) => {
			moduleGroups.push(moduleGroup);
		});
	});

	/* Selected module data & tasks */
	const selectedModuleGroupData = moduleGroups.find((moduleGroup) => {
		return moduleGroup.id === selectedModuleGroupId;
	});
	
	/* Get all players (of game) */
	const players = (gameId 
		? playersData.filter((p) => {return p.gameId === gameId;})
		: playersData
	);

	/* Get component */
	const StatsComponent = (selectedModuleGroupId ? AdminModuleStats : AdminGeneralStats);

	return (
		<div className="AdminGameStatsPopup" onClick={() => {handleToggleGameStats();}}>
			<div className="AdminGameStatsPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminGameStatsPopup-header">
					{gameData && <div className="AdminGameStatsPopup-gameName">
						<span>{getText(adminUiTexts.gameName, languageId)}:</span>
						<span>{gameData.name}</span>
					</div>}
					{!gameData && <div className="AdminGameStatsPopup-gameName">
						<span>{getText(adminUiTexts.fullStats, languageId)}</span>
					</div>}
					<div className="AdminGameStatsPopup-closeBtn" onClick={() => {handleToggleGameStats();}} />
				</div>

				{/* Popup body */}
				<div className="AdminGameStatsPopup-body">
					{/* Selected module */}
					<div className="AdminGameStatsPopup-module">
						{/* Back btn */}
						{selectedModuleGroupId && <div className="AdminGameStatsPopup-backBtn">	
							<Button 
								classes={['facilitator']}
								text={getText(adminUiTexts.gameStatsPopup.backBtn, languageId)}
								onClick={() => {setSelectedModuleGroupId(null);}}
							/>
						</div>}
						{/* Module title */}
						{selectedModuleGroupData && <div className="AdminGameStatsPopup-moduleTitle">
							<span>{getText(selectedModuleGroupData.title, languageId)}</span>
						</div>}
					</div>

					{/* General / module stats */}
					<StatsComponent 
						isFullStats={gameId ? false : true}
						languageId={languageId} 
						gameId={gameId}
						gamesData={gamesData}
						selectedModuleGroupData={selectedModuleGroupData} 
						players={players} 
						setSelectedModuleGroupId={setSelectedModuleGroupId}
					/>
				</div>
			</div>
		</div>
	);

};

AdminGameStatsPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string,
	gamesData: PropTypes.array.isRequired,
	playersData: PropTypes.array.isRequired,
	handleToggleGameStats: PropTypes.func.isRequired,
};

export default AdminGameStatsPopup;
