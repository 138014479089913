
/**
 * Check if the conditions for an effect are fulfilled
 * @param {string} startTime
 * @param {number} minutesPassed 
 * @returns 
 */
export function checkIfConditionsAreFulfilled(effect, errors) {
	let conditionsAreFulfilled = true;

	if (effect.conditions && effect.conditions.length > 0) {
		effect.conditions.forEach((condition) => {
			if (!conditionsAreFulfilled) return;

			if (condition.type === 'no-errors' && errors > 0) {
				conditionsAreFulfilled = false;
			}
		});
	}

	return conditionsAreFulfilled;
};
