import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {checkIfValidEmail} from 'helpers/admin-helper';
import Button from 'components/ui/button/button';
import './admin-add-facilitator-popup.scss';

const AdminAddFacilitatorPopup = ({languageId, gameId, togglePopup, addFacilitatorToGame}) => {
	/* Loading, feedback */
	const [isLoading, setIsLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	
	/* Input facilitator e-mail */
	const [email, setEmail] = useState('');
	const updateEmail = (e) => {
		setEmail(e.target.value);
		setErrorMsg(null);
		setSuccessMsg(null);
	};


	/**
	 * Handle add facilitator
	 */
	const handleAddFacilitator = () => {
		if (isLoading) return; 

		setErrorMsg(null);
		setSuccessMsg(null);
		setIsLoading(true);
		const {isValidEmail, emailFeedback} = checkIfValidEmail(email);
		
		if (isValidEmail) {
			/* Valid e-mail, add facilitator */
			addFacilitatorToGame(gameId, email.toLowerCase()).then((response) => {
				if (response.status === 'error') {
					setIsLoading(false);
					setErrorMsg(response.feedback);			
				} else {
					setIsLoading(false);
					togglePopup();
				}
			});
		} else {
			/* Invalid e-mail */
			if (getText(adminUiTexts.addFacilitatorToGamePopup[emailFeedback], languageId).length > 0) {
				setErrorMsg(getText(adminUiTexts.addFacilitatorToGamePopup[emailFeedback], languageId));
			}
			setIsLoading(false);
		}
	};


	return (
		<div className="AdminAddFacilitatorPopup" onClick={() => {togglePopup();}}>
			<div className="AdminAddFacilitatorPopup-content" onClick={(e) => {e.stopPropagation();}}>

				{/* Popup header */}
				<div className="AdminAddFacilitatorPopup-header">
					<div className="AdminAddFacilitatorPopup-title">
						<span>{getText(adminUiTexts.addFacilitatorToGamePopup.title, languageId)}</span>
					</div>
					<div 
						className="AdminAddFacilitatorPopup-closeBtn" 
						onClick={() => {togglePopup();}}
					/>				
				</div>

				{/* Popup body */}
				<div className="AdminAddFacilitatorPopup-body">
					<div className="AdminAddFacilitatorPopup-input">
						<input 
							type="text"
							name="email"
							placeholder={getText(adminUiTexts.addFacilitatorToGamePopup.emailPlaceholder, languageId)}
							value={email ? email : ''}
							onChange={(e) => {updateEmail(e);}}
						/>
					</div>
					<div className={'AdminAddFacilitatorPopup-feedback' 
						+ (errorMsg ? ' error' : '') + (successMsg ? ' success' : '')}
					>
						{errorMsg && <span>{errorMsg}</span>}
						{successMsg && <span>{successMsg}</span>}
					</div>
					<div className="AdminAddFacilitatorPopup-confirmBtn">
						<Button 
							isLoading={isLoading}
							classes={['facilitator']}
							text={getText(adminUiTexts.addFacilitatorToGamePopup.okBtn, languageId)}
							onClick={() => {handleAddFacilitator();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

AdminAddFacilitatorPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	gameId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired,
	addFacilitatorToGame: PropTypes.func.isRequired,
};

export default AdminAddFacilitatorPopup;
