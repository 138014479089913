import React from 'react';
import PropTypes from 'prop-types';
import './logo.scss';

const Logo = ({type}) => {
	if (type === 'cgl') {
		return (
			<a 
				className={'Logo' + (type ? ' ' + type : '')}  
				href="https://cphgamelab.dk" 
				target="_blank" 
				rel="noreferrer"
			>Copenhagen Game Lab</a>
		);
	}
	return (
		<div className={'Logo' + (type ? ' ' + type : '')} />
	);
};

Logo.propTypes = {
	type: PropTypes.string
};



export default Logo;